const ERR_MESSAGE = {
START_DATE_EMPTY: "Enter start date",
END_DATE_EMPTY: "Enter end date",
PRICE_EMPTY: "Enter price",
USERS_EMPTY: "Enter number of members",
MESSAGE_EMPTY: "Enter message",
EMPTY_NAME:"Enter name",
MOBILE_EMPTY: "Enter mobile",
START_DATE_INVALID:"Enter valid date",
ENTER_OPTION: "Please select at least one option",
EMAIL_EMPTY: "Enter email",
MESSAGE_EMPTY: "Enter message",
EMAIL_INVALID: "Enter valid email address",
LOCATION_EMPTY: "Enter Location",
ROLE_EMPTY: "Enter Role",
INVALID_NAME1:"Enter valid name",
INVALID_MOBILE_MIN: "Minimum 9 digits are required",
INVALID_LOCATION: "Invalid location",
EXPERIENCE_EMPTY: "Enter Experience",
INTERESTS_EMPTY: "Enter Interests",
SPECIALITY_EMPTY: "Enter Speciality",
TAP_EMPTY: "Please enter about section.",
PASSWORD_EMPTY: "Enter password",
INVALID_NAME:"Invalid name",
EMPTY_FULLNAME: "Enter full name",
EMPTY_ORGNAME: "Enter organization name",
INVALID_EMPNAME_MIN: "Member name should have minimum 3 characters",
INVALID_EMPNAME_MAX: "Member name should have maximum 30 characters",
INVALID_FULLNAME_MAX1:"Full name should have maximum 30 characters",
EMPTY_EMPNAME: "Enter Member name",
PASSWORD_NEW_EMPTY: "Enter new password",
INVALID_FULLNAME: "Full name should have minimum 3 characters",
INVALID_FULLNAME_MAX: "Full name should have maximum 30 characters",
INVALID_FULLNAME1: "Full name should have maximum 30 characters",
INVALID_ORGNAME_MIN: "Organization name should have minimum 3 characters",
INVALID_ORGNAME_MAX: "Organization name should have maximum characters",
INVALID_ORGNAME: "Member name should have minimum 3 characters",
INVALID_NAME_MIN: "Name should have minimum 3 characters",
INVALID_NAME_MAX: "Name should have maximum 30 characters",
INVALID_PASSWORD:
  "Password must contain 8 or more characters including 1 uppercase letter, 1 lower case letter, 1 numeric and a special character",
EMPTY_CONFIRMPASSWORD: "Enter confirm password",
NOTMATCHED: "New password and confirm password do not match",
CATEGORY_EMPTY: "Enter category",
OPTIONS_EMPTY: "Enter options",
OLD_PASSWORD_EMPTY: "Enter old password",
NEW_PASSWORD_EMPTY: "Enter new password",
CONFIRM_PASSWORD_EMPTY: "Enter confirm password",
CARD_NUMBER_EMPTY: "Enter card number",
CARD_NUMBER_INVALID: "Enter valid card number",
CARD_DATE_EMPTY: "Enter card expiry date ",
CARD_DATE_INVALID: "Enter valid expiry date",
EMOJI: "Emojis are not allowed",
CARD_CVV_EMPTY: "Enter cvv number",
CARD_CVV_INVALID: "Enter valid cvv number",
TAP_INVALID: "Please enter value above 0",
TAP_EMPTY: "Enter tap limit",
};
export default ERR_MESSAGE;
