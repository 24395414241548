import IMAGES from "../../Middleware/images";

export const HaptaptImages = ({
  imageName,
  imageAlt = "",
  className,
  style
}) => {
  console.log("imagename", IMAGES[imageName])
  return <img style={style} className={className} src={IMAGES[imageName]} alt={imageAlt} />
}