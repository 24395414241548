import React, { useEffect, useState } from 'react'
import IMAGES from '../Middleware/images'
import '../Assets/Styles/steps.css'
const Steps = (props) => {
  const [alreadyExist,setAlreadyExist]=useState(false);
  const [added,setAdded]=useState(false);
  const handleChange = (e) => {
    props?.setField(e.target.value)
  }
  useEffect(() => {
    const extractCatId = () => {
      const dataArray = props?.categoryList[props?.steps - 2]?.subcatdata || props?.categoryList[props?.steps - 2]?.osubcatdata;
      if (dataArray && dataArray.length > 0) {
        const catIdFromDataArray = dataArray[0]?.cat_id;
        props?.setCatId(catIdFromDataArray);
      }
    };
    extractCatId();
  }, [props?.categoryList, props?.steps])

  const handleKeydown = (e) => {
    if ((e.code === "Enter" || e.code === "NumpadEnter" || e.key === "," || e.key === "Enter") && e.target.value.trim().length !== 0) {
      const inputValue = e.target.value.trim()
      const matchingObject = getMatchingObject(inputValue?.toLowerCase());
      if (!matchingObject) {
        props.textApi(props.catId);
        setAdded(true)
        setAlreadyExist(false)
      } else {
        setAlreadyExist(true)
        props.chipClick(matchingObject);
      }
      setTimeout(() => {
        setAdded(false);
        setAlreadyExist(false);
      }, 1500);      
      handleClear();
    }
  }

  function myTimer() {
    if(added==true || alreadyExist==true){
      setAdded(false)
      setAlreadyExist(false)
    }
  }

  const getMatchingObject = (fieldName) => {
    const dataArray = props?.categoryList[props?.steps - 2]?.subcatdata
      ? props?.categoryList[props?.steps - 2]?.subcatdata
      : props?.categoryList[props?.steps - 2]?.osubcatdata;
    if (!dataArray) return null;
    return dataArray.find(item => item.name.toLowerCase() === fieldName) || null;
  };

  const dataArray = props?.categoryList[props?.steps - 2]?.subcatdata ? props?.categoryList[props?.steps - 2]?.subcatdata : props?.categoryList[props?.steps - 2]?.osubcatdata

  const handleClear = () => {
    props?.setField("")
  }

  return (
    <>
      <section className="category-step-two">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="category-step text-center">
                <p>haptapt works best when you add as many details (haptapt filter tags) as possible so you and your coworkers show up in the most relevant searches.</p>
                <p>Only add what you’re comfortable with coworkers knowing about you.</p>
                <div className="details-head mt-5 mb-4 text-center">
                  <a href="" className="step-btn ">Step {props?.steps}/{props?.categoryList.length + 1}</a>
                </div>
              </div>
              <div className="category-experience-step text-center">
                <h2 className="my-4">{props?.categoryList[props?.steps - 2]?.name}</h2>
                <div className="col-lg-6 mx-auto">
                  <div className="text-end position-relative" align="right">
                    <input
                      value={props?.field ? props?.field?.replace(/,/g, '') : ''}
                      onChange={handleChange}
                      onKeyDown={handleKeydown}
                      type="text" className="form-control comm-radius input-shadow comm-input" placeholder={added==false? "Type here ...":"Added"} />


                    <div className='icon-cross' >
                      <div align="right" style={{display:'inline-flex',paddingTop:'5px',paddingRight:'4px',color:'#e57f7f'}}>
                       {alreadyExist==true ? "Keyword already added!":''}
                      </div>
                      {props?.field == "," ? "" : props?.field ?
                        <img src={IMAGES.CROSS_1} style={{position:'absolute',marginTop:'10px'}} onClick={handleClear}/>
                        : ""
                      }
                    </div>
                  </div>
                  <div className="category-exp-filters my-4" >
                    {dataArray?.map((item, index) => (
                      <>
                        <p onClick={() => props?.chipClick(item)} key={item?.id} style={{ cursor: "pointer" }} 
                        className={props?.chipActiveArr[props?.steps - 2][item?.id] ? " category-filter-chips active" : " category-filter-chips"}><span >{item?.name}</span> <img src={IMAGES.CHIP_CROSS} className="chip-img" alt="img-fluid" /></p>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Steps;