import React, { useEffect, useState, useRef, cloneElement } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IMAGES from '../../../Middleware/images';
import "../../../Assets/Styles/profile.css"
import ERR_MESSAGE from '../../../Helper/error-helper';
import DEVELOPMENT_CONFIG from "../../../Helper/config"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Autocomplete } from '@mui/material';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useContext } from 'react';
import apiHelper from '../../../Helper/api-helper';
const libraries = ['places'];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CategoryComponent = ({fields1, setFields1}) => {

    const [categoryList, setCategoryList] = useState([]);
    const [subCategory, setSubCategoryData] = useState([]);

    const [openDropdown, setOpenDropdown] = useState(false)

    const [editOpen, setEditOpen] = React.useState(false);

    const [editId, setEditId] = useState(null);
    const [isLoading, setLoading] = useState(false);
    async function textApi(value) {
        let data = {
            "name": value,
            "cat_id": editId
        };
        setLoading(true);
        try {
            let result = await apiHelper.postRequest("auth/create-subcategory", JSON.stringify(data));
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                const newName = result?.body?.name;
                const storedArrayString = localStorage.getItem("subCatArr");
                const storedArray = JSON.parse(storedArrayString);
                const newValues = [...storedArray, newName];
                localStorage.setItem("subCatArr", JSON.stringify(newValues))
                getAPI()
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleKeyDown = (event) => {
        const { key, target } = event;
        if ((key === 'Enter' || key === ',' || event.key === "NumpadEnter") && target.value.trim().length > 0) {
            event.preventDefault();
            const value = target.value.trim();
            if (!fields1?.previousSubCat.includes(value.toLowerCase())) {
                textApi(value);
                setOpenDropdown(true)
            }
            else {
                const storedSubCatArr = JSON.parse(localStorage.getItem('subCatArr'))
                const newValues = [...storedSubCatArr, value];
                if (fields1?.previousSubCat.includes(value.toLowerCase())) {
                    if (storedSubCatArr.includes(value.toLowerCase())) {
                        const filterData = storedSubCatArr.filter((item) => item != value.toLowerCase())
                        setFields1({
                            ...fields1,
                            subCatArr: filterData,
                        })
                        localStorage.setItem("subCatArr", JSON.stringify(filterData))
                    } else {
                        setFields1({
                            ...fields1,
                            subCatArr: newValues,
                        })
                        localStorage.setItem("subCatArr", JSON.stringify(newValues))
                    }
                }
                setOpenDropdown(true)
            }
        }
    }

    const handleEditOpen = (id, catName, subcatdata) => {
        document.body.classList.add("modal-open");
        setSubCategoryData(subcatdata)
        const selectedNames = subcatdata
            .filter(item => item.selected !== null)
            .map(item => item.name);

        const selectedIds = subcatdata
            .map(item => item.id);
        setEditId(id)
        setEditOpen(true);
        setFields1({
            category: catName,
            subCat: "",
            subCatArr: selectedNames,
            previousSubCat: subcatdata?.map(item => item.name),
            previousSubCatId: selectedIds
        })
        localStorage.setItem('subCatArr', JSON.stringify(selectedNames));

    }

    async function getAPI() {
        setLoading(true);
        let result = await apiHelper.getRequest("auth/category");
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setCategoryList(result?.body);
            if (editId) {
                const matchedCategory = result.body.find(category => category.id === editId);
                if (matchedCategory) {
                    const { name, osubcatdata } = matchedCategory;
                    const selectedIds = osubcatdata
                        .filter(item => item.selected !== null)
                        .map(item => item.id);
                    const storedSubCatArr = localStorage.getItem('subCatArr');
                    const newStore = JSON.parse(storedSubCatArr)
                    setFields1({
                        category: name,
                        subCat: "",
                        subCatArr: newStore,
                        previousSubCat: osubcatdata?.map(item => item.name),
                        previousSubCatId: selectedIds
                    });
                    setSubCategoryData(osubcatdata)
                }
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAPI();
    }, [])

    const [errors, setErrors] = useState("");
    const autocompleteRef = React.useRef();

    const handleAutocompleteClick = () => {
        autocompleteRef.current.focus();
        setOpenDropdown(true)
    };

    const handleEditClose = () => {
        document.body.classList.remove("modal-open");
        localStorage.removeItem('subCatArr');
        setErrors("")
        setFields1(
            {
                category: "",
                subCat: "",
                subCatArr: [],
                previousSubCat: [],
                previousSubCatId: []
            }
        )
        setEditOpen(false);
    }
    const success = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }
    const error = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }

    const handleValidation2 = () => {
        let errors = {};
        let formIsValid = true;

        const { category, subCatArr } = fields1;
        if (!category || category.trim().length === 0) {
            formIsValid = false;
            errors["category"] = ERR_MESSAGE.CATEGORY_EMPTY
        }
        else if (!subCatArr || subCatArr.length === 0) {
            formIsValid = false;
            errors["subCatArr"] = ERR_MESSAGE.OPTIONS_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    };



    async function postAPIEditCat(e) {
        e.preventDefault();
        const selectedIds = [];
        const storedSubCatArr = localStorage.getItem('subCatArr');
        const newStore = JSON.parse(storedSubCatArr)
        newStore.forEach(subCat => {
            const matchingSubCategory = subCategory.find(item => item.name === subCat);
            if (matchingSubCategory) {
                selectedIds.push(matchingSubCategory.id);
            }
        });
        if (handleValidation2() && selectedIds) {
            let data = JSON.stringify({
                "changedfrom": fields1.previousSubCatId,
                "changedto": selectedIds
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/update-category", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleEditClose();
                getAPI()
                localStorage.removeItem('subCatArr');
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }

    return (<>
        <ul>
            {
                categoryList.map((item) => {
                    const filteredSubcategories = item.osubcatdata?.filter(subcat => subcat.selected !== null);

                    return (
                        <li key={item.id} >
                            <div className="list-category-main d-flex justify-content-between align-items-center">
                                <div className="list-category-left">
                                    <div className="list-category-head">
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="list-content mt-3">
                                        {
                                            filteredSubcategories?.map((subcat) => {
                                                return (
                                                    <p key={subcat.id} className="list-content-item mb-2 mb-xxl-3">{subcat.name}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div style={{ cursor: "pointer" }} className="list-category-right">
                                    {/* <img onClick={() => handleDeleteOpen(item.id)} src={IMAGES.TRASH_ICON} alt="delete-icon" style={{ cursor: "pointer" }} className="img-fluid" /> */}
                                    <img onClick={() => handleEditOpen(item.id, item.name, item?.osubcatdata)} src={IMAGES.EDIT_ICON} alt="edit-icon" className="img-fluid" />
                                </div>
                            </div>
                        </li>
                    )
                })
            }
        </ul>

            <Modal
                open={editOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box  className="modal modal-custom">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                        <div className="modal-content modal-content-custom">
                            <div onClick={handleEditClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                                <img src={IMAGES.CROSS} />
                            </div>
                            <div className="signin-head text-center pb-3">
                                <h1>Edit Category</h1>
                            </div>
                            <div className="mb-4  position-relative px-3">
                                <p>
                                    Please modify the category name and its associated options for input on the platform. </p>
                            </div>
                            <form onSubmit={postAPIEditCat}>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <TextField fullWidth label="Enter category name" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="category" value={fields1.category} aria-readonly />
                                    {errors.category && <span className="err_msg">{errors.category}</span>}
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <Autocomplete
                                        ref={autocompleteRef}
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={fields1.previousSubCat}
                                        disableCloseOnSelect
                                        value={fields1.subCatArr}
                                        getOptionLabel={(option) => option}
                                        open={openDropdown}

                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option}
                                            </li>
                                        )}
                                        style={{ width: 500 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Options"
                                                onKeyDown={handleKeyDown}
                                                onFocus={() => setOpenDropdown(true)}
                                                onBlur={() => setOpenDropdown(false)}
                                                onChange={(e) => {
                                                    setErrors("")
                                                    const value = e.target.value;
                                                    setOpenDropdown(fields1.subCatArr.some(item => item === value));
                                                }}
                                                onClick={() => setOpenDropdown(true)}
                                                InputProps={{ ...params.InputProps, inputProps: { ...params.inputProps, inputMode: 'search' } }}
                                            />
                                        )}

                                        onChange={(event, newValue) => {
                                            setErrors("");
                                            setFields1({
                                                ...fields1,
                                                subCatArr: newValue,
                                            });
                                            setOpenDropdown(true);
                                            localStorage.setItem('subCatArr', JSON.stringify(newValue));

                                        }}
                                        onClick={handleAutocompleteClick}
                                        className="custom-autocomplete"
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                    />

                                    {errors.subCatArr && <span className="err_msg">{errors.subCatArr}</span>}
                                </div>

                                <div className="text-center mb-5 position-relative submit-head">
                                    <button type="submit" className="yellow-btn d-flex align-items-center">Update <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
</>)
}

export default CategoryComponent;