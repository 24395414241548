import React, { useEffect, useRef, useState, useMemo } from "react";
import "../../Assets/Styles/organization-member.css";
import IMAGES from "../../Middleware/images";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import apiHelper from "../../Helper/api-helper";
import ERR_MESSAGE from "../../Helper/error-helper";
import DEVELOPMENT_CONFIG from "../../Helper/config";
import ReactLoader from "../../ControlComponents/react-loader";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import titles from "../../Helper/title";
import "react-toastify/dist/ReactToastify.css";
import ROUTES from "../../Middleware/routes";
import TextField from "@mui/material/TextField";
import Pagination from "../../Components/pagination";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import RegisterEmployee from "../Admin/component/register-employe";
import { InviteSent } from "../Admin/component/invite-sent";
import { MemberAdded } from "../Admin/component/member-add";
import { Stack } from "@mui/material";
const libraries = ['places'];

let PageSize = 10;
const OrganizationMember = () => {

  const [invteModel, setInviteModel] = useState(false);
  const [memberAddedModel, setMemberAddedModel] = useState(false);


  const inputRef = useRef(null);
  const { state } = useLocation();
  const [catList, setCatList] = useState([]);
  const [filteredMember, setFilteredMember] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [editProfle, setEditProfile] = useState(false);
  const [userDetail, setUserDetail] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [catOpen, setCatOpen] = useState(false);
  const [selectedRows1, setSelectedRows1] = useState([]);
  const [status, setStatus] = useState("");
  const [pdfLink, setPdfLink] = useState('');
  const [editOpen, setEditOpen] = React.useState(false);
  const [editId, setEditId] = useState([]);
  const btnRef = useRef();
  const [fields1, setFields1] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
    email: "",
    name: "",
    place: "",
    longitude: "",
    latitude: "",
    roleTitle: "",
    category: "",
    subCat: "",
    subCatArr: [],
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [billingList, setBillingList] = useState();
  const [openActivate, setOpenActivate] = useState(false);
  const [deltId, setDeltId] = useState(null);

  const handleOpen = (e) => {
    // if (userDetail?.is_profile == 1) {
      document.body.classList.add("modal-open");
      setOpen(true);
    // }
  };

  const handleCatOpen = () => {
    document.body.classList.add("modal-open");

    setCatOpen(true);
  }

  const handleCatClose = () => {
    document.body.classList.remove("modal-open");

    setErrors("")
    setFields2(
      {
        category: "",
        subCat: "",
        subCatArr: []
      }
    )
    setCatOpen(false);
  }

  const handleRowClick1 = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const [openDelete, setOpenDelete] = useState(false);
  const [openOrgDelete, setOpenOrgDelete] = useState(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);
  const [openOrgDeactivate, setOpenOrgDeactivate] = useState(false);
  const [activeRow, setActiveRow] = useState(null);

  const tableRef = useRef(null);
  const handleRowClick = (rowId) => {
    setActiveRow(rowId);
  };

  const handleMemberList = (id) => {
    navigate(ROUTES.EMPLOYEE_PROFILE, {
      state: {
        id: id
      }
    })
  }

  const handleDocumentClick = (e) => {
    if (tableRef.current && !tableRef.current.contains(e.target)) {
      setActiveRow(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleEditProfileOpen = () => {
    document.body.classList.add("modal-open");

    setFields1({
      ...fields1,
      latitude: userDetail.latitude,
      longitude: userDetail.longitude,
      name: userDetail?.name?.replace(/-/g, ' '),
      place: userDetail.location,
      email: userDetail.email,
      roleTitle: userDetail.designation,
    });
    setEditProfile(true);
  };

  const handleDeactivateClose = () => {
    document.body.classList.remove("modal-open");

    setOpenDeactivate(false);
  }
  const handleActivateOpen = (id, status) => {
    document.body.classList.add("modal-open");

    setSelectedRows1(prevSelectedRows => [...prevSelectedRows, id]);
    setStatus(status)

    setOpenActivate(true);
  };
  const handleActivateClose = () => {
    document.body.classList.remove("modal-open");

    setOpenActivate(false);
  }
  const handleOrgDeactivateOpen = (status) => {
    if (inputRef?.current?.checked === false) {
      return;
    }
    setOpenOrgDeactivate(true);
    document.body.classList.add("modal-open");

    setStatus(status)
  };
  const handleOrgDeactivateClose = () => {
    document.body.classList.remove("modal-open");
    setOpenOrgDeactivate(false);
  }
  const handleEditProfileClose = () => {
    document.body.classList.remove("modal-open");

    setErrors("")
    setEditProfile(false);
  }

  const handleDeleteOrgOpen = (status) => {
    document.body.classList.add("modal-open");

    setStatus(status)
    setOpenOrgDelete(true);
  };
  const handleDeleteOrgClose = () => {
    document.body.classList.remove("modal-open");

    setOpenOrgDelete(false);
  }

  const handleDeleteOpen = (id, status) => {
    document.body.classList.add("modal-open");
    setStatus(status)
    setSelectedRows1(prevSelectedRows => [...prevSelectedRows, id]);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    document.body.classList.remove("modal-open")
    setOpenDelete(false);
  }
  const handleDeactivateOpen = (id, status) => {
    document.body.classList.add("modal-open");
    setSelectedRows1(prevSelectedRows => [...prevSelectedRows, id]);
    setStatus(status)
    setOpenDeactivate(true);
  };

  const handleClose = () => {
    document.body.classList.remove("modal-open");
    setErrors("")
    setOpen(false);
  }
  const [errors, setErrors] = useState("");
  const [fields, setFields] = useState({
    email: "",
    userName: "",
  });
  const [fields2, setFields2] = useState({
    category: "",
    subCat: "",
    subCatArr: [],
  });

  const inputRef1 = useRef(null);
  let key = "AIzaSyDFkw6-U8mAQEGTOC77ZYrIojD4isGoNgg";
  const handlePlaceChanged = () => {
    setErrors("")
    const [place] = inputRef1?.current?.getPlaces();
    if (place) {
      setFields1({
        ...fields1,
        place: place.formatted_address,
        latitude: `${place.geometry.location.lat()}`,
        longitude: `${place.geometry.location.lng()}`,
      });

    }
  };


  const handleEditOpen = (id, catName, subcatdata) => {
    document.body.classList.add("modal-open");

    setEditId(id)
    setEditOpen(true);
    setFields2({
      category: catName,
      subCat: "",
      subCatArr: subcatdata.map((item) => item.name)
    })
  }
  const handleEditClose = () => {
    document.body.classList.remove("modal-open");

    setErrors("")
    setFields2(
      {
        category: "",
        subCat: "",
        subCatArr: []
      }
    )
    setEditOpen(false);
  }
  async function postAPIEditCat(e) {
    e.preventDefault();
    if (handleValidation2()) {
      let data = JSON.stringify({
        "cat_id": editId,
        "name": fields2.category,
        "subcatArr": fields2.subCatArr
      });
      setLoading(true);
      let result = await apiHelper.postRequest("auth/edit-org-category", data)
      if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false);
        success(result.message)
        handleEditClose();
        getAPI()
        getCategoryAPI()
      }
      else {
        error(result.message)
        setLoading(false)
      }
    }
  }
  const handleChange2 = (e) => {
    setErrors("");

    const name = e.target.name;
    const value = e.target.value;

    if (name === "place") {
      setFields1({
        ...fields1,
        [name]: value,
        latitude: "",
        longitude: ""
      });
    }
    else {
      setFields1({
        ...fields1,
        [name]: value,
      });
    }

  };

  const navigate = useNavigate();
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
    const { email, userName } = fields;
    if (!email || email.trim().length === 0) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_EMPTY;
    } else if (reg.test(email.toLowerCase()) === false) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
    }
    if (!userName || userName.trim().length === 0) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.EMPTY_EMPNAME;
    } else if (userName.length < 3 || userName.length > 30) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.INVALID_FULLNAME;
    }
    else if (emojiRegex.test(userName)) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.EMOJI;
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleValidationProfile = () => {
    let errors = {};
    let formIsValid = true;
    const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const { email, name, place, longitude, latitude, roleTitle } = fields1;
    if (!name || name.trim().length === 0) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.EMPTY_NAME
    } else if (name.length < 3) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_NAME_MIN
    }
    else if (name.length > 30) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_NAME_MAX
    }
    else if (emojiRegex.test(name)) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_NAME;
    }

    if (!email || email.trim().length === 0) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
    }
    else if (reg.test(email.toLowerCase()) === false) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_INVALID
    }
    if (!place || place.trim().length === 0) {
      formIsValid = false;
      errors["place"] = ERR_MESSAGE.LOCATION_EMPTY;
    }
    else if (!longitude || longitude?.trim().length === 0 || !latitude || latitude?.trim().length === 0) {
      formIsValid = false;
      errors["place"] = ERR_MESSAGE.INVALID_LOCATION;
    }
    else if (emojiRegex.test(place)) {
      formIsValid = false;
      errors["place"] = ERR_MESSAGE.EMOJI;
    }
    if (!roleTitle || roleTitle.trim().length === 0) {
      formIsValid = false;
      errors["roleTitle"] = ERR_MESSAGE.ROLE_EMPTY
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleValidation2 = () => {
    let errors = {};
    let formIsValid = true;

    const { category, subCatArr } = fields2;
    if (!category || category.trim().length === 0) {
      formIsValid = false;
      errors["category"] = ERR_MESSAGE.CATEGORY_EMPTY
    }
    // if (!subCatArr || subCatArr.length === 0) {
    //   formIsValid = false;
    //   errors["subcatArr"] = ERR_MESSAGE.OPTIONS_EMPTY
    // }
    setErrors(errors);
    return formIsValid;
  };
  const success = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };
  const error = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };


  async function getActionApi(e) {
    e.preventDefault();
    let id = Array.isArray(state.id) ? state.id : [state.id];
    let data = JSON.stringify({
      "id": id,
      "status": status
    });
    setLoading(true);
    let result = await apiHelper.postRequest(
      "auth/change-account-status",
      data
    );
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      setStatus("");
      success(result.message);
      refreshFilteredOrganizationList();
      getAPI();
      handleOrgDeactivateClose()

    } else {
      error(result.message);
      setLoading(false);
    }
  }
  async function getActionDeleteApi(e) {
    e.preventDefault();
    let id = Array.isArray(state.id) ? state.id : [state.id];
    let data = JSON.stringify({
      "id": id,
      "status": status
    });
    setLoading(true);
    let result = await apiHelper.postRequest(
      "auth/change-account-status",
      data
    );
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      setStatus("");
      localStorage.setItem("message", result.message);
      refreshFilteredOrganizationList();
      getAPI();
      handleDeleteOrgClose()
      navigate(ROUTES.ORGANIZATION_LIST);

    } else {
      error(result.message);
      setLoading(false);
    }
  }
  async function getOrgActivateAPI(status) {
    if (inputRef?.current?.checked === true) {
      return;
    }
    setLoading(true);
    let id = Array.isArray(state.id) ? state.id : [state.id];
    let data = JSON.stringify({
      "id": id,
      "status": status
    });
    setLoading(true);
    let result = await apiHelper.postRequest(
      "auth/change-account-status",
      data
    );
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      setStatus("");
      success(result.message);
      refreshFilteredOrganizationList();
      getAPI();
    } else {
      error(result.message);
      setLoading(false);
    }

  }
  async function postAPIEditProfile(e) {
    e.preventDefault();
    if (handleValidationProfile()) {
      let data = JSON.stringify({
        "name": fields1.name,
        "designation": fields1?.roleTitle,
        "id": userDetail.id,
        "location": fields1.place,
        "latitude": fields1.latitude,
        "longitude": fields1.longitude
      });
      setLoading(true);
      let result = await apiHelper.postRequest("auth/set-your-profile", data);
      if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false);
        success(result.message);
        handleEditProfileClose();
        getAPI();
      } else {
        error(result.message);
        setLoading(false);
      }
    }
  }

  async function getActionApi1(e) {
    e.preventDefault();
    let data = JSON.stringify({
      "id": selectedRows1,
      "organization_id": state.id,
      "status": status
    });
    setLoading(true);
    let result = await apiHelper.postRequest(
      "organization/change-user-status",
      data
    );

    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      setStatus("");
      setSelectedRows1([]);
      success(result.message);
      refreshFilteredOrganizationList();
      getAPI();
      handleActivateClose()
      handleDeactivateClose()
      handleDeleteClose()
    } else {
      error(result.message);
      setLoading(false);
    }

  }
  const id = localStorage.getItem("id")
  async function sendInvite(invite_id) {

    let data = JSON.stringify({
      "id": invite_id,
      "organisation_id": id,
    })
    setLoading(true);
    let result = await apiHelper.postRequest("organization/re-invite-user", data)
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false)
      // success(result.message);
      // getAPI()
      setInviteModel(true)
    }
    else {
      error(result.message)
      setLoading(false)
    }

  }

  const sendInviteToOriginalUser = async (orgAdminId) => {
      let submitData = {
        id: orgAdminId
      }
      setLoading(true);
      let result = await apiHelper.updatedPostRequest(
        "organization/re-invite-org-user",
        submitData
      );
      if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false)
      // success(result.message);
      // getAPI()
      setInviteModel(true)
    }
    else {
      error(result.message)
      setLoading(false)
    }
  }

  async function getAPI() {
    setLoading(true);
    let result = await apiHelper.getRequest(
      `organization/organization-detail?id=${state?.id}`
    );
    console.log("organization detail $$$", result)
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setUserDetail(result.body.user);
      setMemberList(result.body.memberList);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  async function getAPIBilling() {
    setLoading(true);
    let result = await apiHelper.getRequest(`auth/billing-list?id=${state?.id}`);
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setBillingList(result?.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return memberList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, memberList]);

  const currentTableData1 = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredMember?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredMember]);

  const currentTableData2 = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return billingList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, billingList]);

  // if (userDetail?.is_profile == 1) {
  //   if (btnRef) {
  //     btnRef.current.style.backgroundColor = "#fed71a";
  //     btnRef.current.style.cursor = "pointer";
  //   }
  // }


  const handleChange3 = (e) => {
    setErrors("")
    const name = e.target.name;
    const value = e.target.value;

    setFields2({
      ...fields2,
      [name]: value,
    });
  };
  async function getCategoryAPI() {
    setLoading(true);
    await apiHelper
      .getRequest(`auth/admin-category-list?id=${state?.id}&sort=alpha`)
      .then((result) => {
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
          setLoading(false);
          setCatList(result?.body)
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  async function getInvoiceAPI(invoiceId) {
    setLoading(true);
    let result = await apiHelper.getRequest(`auth/invoice-download?invoice_id=${invoiceId}`);
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setPdfLink(result?.body?.pdf);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (pdfLink) {
      const link = document.createElement('a');
      link.href = pdfLink;
      link.target = '_blank';
      link.download = 'document.pdf';
      link.click();
    }
  }, [pdfLink]);



  async function postCatAPI(e) {
    e.preventDefault();
    if (handleValidation2()) {
      let data = JSON.stringify({
        "name": fields2.category,
        "subcatArr": fields2.subCatArr,
        "organisation_id": state.id
      });
      setLoading(true);
      let result = await apiHelper.postRequest("auth/create-org-category", data)
      if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false);
        success(result.message)
        handleCatClose();
        getAPI()
        getCategoryAPI()
      }
      else {
        error(result.message)
        setLoading(false)
      }
    }
  }


  const handleSearch = (e) => {
    let text = e.target.value;
    setSearch(text);
    const filteredProducts = memberList?.filter((product) =>
      product?.name?.toLowerCase().includes(search.toLowerCase()) || product?.email?.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredMember(filteredProducts);
  };
  const handleClear = () => {
    setSearch("");
  };

  const refreshFilteredOrganizationList = () => {
    const searchText = search.toLowerCase();
    const filteredProducts = memberList?.filter((product) =>
      product?.name?.toLowerCase().includes(searchText) || product?.email?.toLowerCase().includes(searchText)
    );
    setFilteredMember(filteredProducts);
  };

  useEffect(() => {
    refreshFilteredOrganizationList();
  }, [memberList, search]);

  useEffect(() => {
    getAPI();
    getCategoryAPI()
    getAPIBilling();
    memberList.sort((a, b) => a?.name?.localeCompare(b.name))
  }, []);

  const getlist = () => {
    if (search.length <= 0) {
      return currentTableData
    }
    else if (currentTableData1.length <= 0) {
      return []
    }
    else {
      return currentTableData1
    }
  }

  return (
    <>
      <Helmet>
        <title>{titles.organizationDettail}</title>
      </Helmet>
      {/* <!--member-organization-section --> */}

      <section className="pt-5">
        <div className="container">
          <div className="row align-items-center pb-4">
            <div className="col-12 col-lg-4">
              <nav>
                <ul onClick={() => navigate(ROUTES.ORGANIZATION_LIST)} style={{ cursor: "pointer" }} className="d-flex gap-2 align-items-center mb-0">
                  <li>
                    <img
                      src={IMAGES.BACK_ARROW}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(-1)}
                    />
                  </li>
                  <li>{userDetail?.name?.replace(/-/g, ' ')}</li>
                </ul>
              </nav>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row align-items-center text-end text-md-start">
                <div className="col-12">
                  <div className="switch-toggle-head text-end">
                    <div style={{ zIndex:"1" }} className="d-flex justify-content-end align-items-center switch-delete-acc position-relative">
                      <div
                        className="delete-acc-link text-end me-1 me-sm-2"
                        style={{ cursor: "pointer" }}
                      >
                        <a onClick={() => handleDeleteOrgOpen("3")}>Delete Account</a>
                      </div>
                      <label className="switch">
                        <input
                          ref={inputRef}
                          className="switch-input"
                          type="checkbox"
                          checked={userDetail?.is_active === 1 ? true : false}
                        />
                        {
                          userDetail?.invite_status === 0 ? <>
                            <div className="switch-button switch-button-grey ">
                              <span
                                className="switch-button-left"
                                style={{ cursor: "not-allowed" }}

                              >
                                Deactivate
                              </span>
                              <span
                                className="switch-button-right"
                                style={{ cursor: "not-allowed" }}

                              >
                                Activate
                              </span>
                            </div>
                          </> :
                            <>
                              <div className="switch-button">
                                <span
                                  className="switch-button-left"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleOrgDeactivateOpen("2")}
                                >
                                  {userDetail?.is_active === 2
                                    ? "Deactivated"
                                    : "Deactivate"}
                                </span>
                                <span
                                  className="switch-button-right"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => getOrgActivateAPI("1")}
                                >
                                  {userDetail?.is_active === 1
                                    ? "Activated"
                                    : "Activate"}
                                </span>
                              </div>
                            </>
                        }

                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-3">
              <div className="company-info back-shadow">
                <div className="company-section-head p-4">
                  <img
                    src={
                      userDetail?.profile_url
                        ? userDetail?.profile_url
                        : IMAGES.DUMMY_IMAGE
                    }
                    alt=""
                    className="img-fluid rounded-circle logo-img"
                  />
                  <h2 className="company-info-head mb-0 py-3">
                    {userDetail?.name?.replace(/-/g, ' ')}
                  </h2>
                  <ul className="sub-company-list mb-2">
                    <li className="sub-company-list-item company-list-cus">
                      <div className='profile-org-head'>
                        <p className="pe-1 ">Locations:</p>
                      </div>
                      <div className='profile-org-descr'>
                        <p className="fw-900 one-line-text text-cus-align" >{userDetail?.location}</p>
                      </div>
                    </li>
                    <li className="sub-company-list-item company-list-cus">
                      <div className='profile-org-head'>
                        <p className="pe-1">Total Members:</p>
                      </div>
                      <div className='profile-org-descr'>
                        <p className="fw-900 one-line-text text-cus-align">{memberList?.length}</p>
                      </div>
                    </li>
                    <li className="sub-company-list-item company-list-cus">
                      <div className='profile-org-head'>
                        <p className="pe-1">Member Since:</p>
                      </div>
                      <div className='profile-org-descr'>
                        <p className="fw-900 one-line-text text-cus-align">
                          {new Date(userDetail?.created_at).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })
                          }
                        </p>
                      </div>
                    </li>
                    <li className="sub-company-list-item company-list-cus">
                      <div className='profile-org-head'>
                        <p className="pe-1">Email:</p>
                      </div>
                      <div className='profile-org-descr'>
                        <p className="fw-900 one-line-text text-cus-align">{userDetail?.email}</p>
                      </div>
                    </li>

                  </ul>
                  <Stack >
                    <div
                      className="company-info-btns position-relative"
                      style={{ cursor: "pointer" }}
                    >
                      <button
                        onClick={handleEditProfileOpen}
                        type="button"
                        className="transparent-btn custom-transparent d-flex align-items-center"
                      >
                        Edit{" "}
                        <img
                          src={IMAGES.SMALL_ARROW}
                          alt=""
                          className="img-fluid ms-2"
                        />
                      </button>
                    </div>
                      <div  style={{ cursor: "pointer" }}>
                        {(userDetail.is_active == 1 && userDetail.invite_status === 1 && userDetail.is_profile === 0) &&
                            <div onClick={() => sendInviteToOriginalUser(userDetail.id)} className="company-info-btns position-relative mt-4">
                                <button type="button" style={{ background: '#F949B2' }} className="yellow-btn add-category-btn d-flex align-items-center yellow-change-btn ">Invite to haptapt  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                            </div>

                        }

                      </div>

                  </Stack>
                </div>

                <div className="p-4">
                  <div className="d-flex justify-content-between align-items-center mb-3" >
                    <h3 className="company-info-sub-head mb-0 py-2">
                      Categories
                    </h3>
                    <div className="add-category position-relative">

                      <button
                        onClick={handleCatOpen}
                        type="button"
                        className="transparent-btn custom-transparent d-flex align-items-center"
                      >
                        Add{" "}
                        <img
                          src={IMAGES.SMALL_ARROW}
                          alt=""
                          className="img-fluid ms-2"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="company-info-head d-flex align-items-center gap-2">

                    <ul className="sub-company-role-item gap-2 pb-4">
                      {
                        catList?.map((item) => {
                          return (
                            <li className="role-list" style={{ cursor: "pointer" }} onClick={() => handleEditOpen(item.id, item.name, item.osubcatdata)}
                            >{item.name}
                              <img
                                src={IMAGES.EDIT_ICON}
                                alt="edit-icon" className="img-fluid edit-org-icon" /></li>
                          )
                        })
                      }
                    </ul>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-9 mt-3 mt-xl-0">
              <div className="row align-items-center">
                <ul
                  className="nav nav-tabs custom-nav-tab"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link home-nav-link fw-500 active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Members
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link home-nav-link fw-500"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Billing
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-lg-3 py-2 py-lg-4">
                        <div className="list-head">
                          <h2 className="member-head mb-0">Member details</h2>
                        </div>
                      </div>
                      <div className="col-12 col-lg-9 py-2 py-lg-4">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-xxl-7 text-start text-lg-end">
                            <div className="search-category cus-search-category new-search-category position-relative pb-2 pb-sm-0">
                              <input
                                type="serach"
                                placeholder="Search Members..."
                                value={search}
                                onChange={(e) => handleSearch(e)}
                              />
                              <img
                                src={IMAGES.SEARCH_ICON}
                                alt=""
                                className="search-icon"
                              />

                              {search !== "" && <img src={IMAGES.CROSS_1} className="searchCross" style={{ cursor: "pointer" }} onClick={handleClear} />}
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-xxl-5 text-start text-lg-end">
                            <div className="add-category position-relative new-add-category">
                              <button
                                ref={btnRef}
                                type="button"
                                className="yellow-btn custom-catgory-btn d-flex align-items-center"
                                onClick={handleOpen}
                              >
                                Add New Member
                                <img
                                  src={IMAGES.ARROW_GREY}
                                  alt="arrow"
                                  className="img-fluid ms-2"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/** Table Start **************** */}
                      <div className="col-12">
                        <div className="table-responsive organization-table member-table thin-scroll">
                          <table className="table mb-0" ref={tableRef}>
                            <thead>
                              <tr >
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                {/* <th scope="col">Profile</th> */}
                                <th scope="col">Role</th>
                                <th scope="col">Locations</th>
                                <th scope="col">Invite Status</th>
                                <th scope="col"> </th>
                              </tr>
                            </thead>

                            <tbody>
                              <>
                                {getlist()?.length == 0 ?
                                  <tr>
                                    <td colSpan={7}><p className='notOrganization'>No member found</p></td>
                                  </tr> :
                                  getlist()?.map((item) => {
                                    return (
                                      <tr key={item.id} onClick={() => handleRowClick(item.id)} className={activeRow === item.id ? 'active' : ''}>
                                        <td style={{ cursor: "pointer" }} onClick={() => handleMemberList(item.id)}>{item.name?.replace(/-/g, ' ')}</td>
                                        <td style={{ cursor: "pointer" }} onClick={() => handleMemberList(item.id)}>
                                          {item.email.toLowerCase()}
                                        </td>

                                        <td style={{ cursor: "pointer" }} onClick={() => handleMemberList(item.id)}>
                                          <div className="table-content">
                                            {item.designation ? item?.designation : "-"}

                                          </div>
                                        </td>
                                        <td style={{ cursor: "pointer" }} onClick={() => handleMemberList(item.id)}>
                                          {item.location ? item.location : "-"}

                                        </td>
                                        <td style={{ cursor: "pointer" }} onClick={() => handleMemberList(item.id)}>
                                          {/* {item.is_active == 2 ? "deactivated" : <>{item.invite_status === 0 ? "invited" : "active"}</>
                                    } */}
                                          {item.is_active == 2 ? "deactivated" : <>
                                            {item.invite_status === 0 ? "invited" :
                                              (item.is_active == 1 && item.invite_status === 1 && item.is_profile === 0) ? "added" : "active"}</>}


                                        </td>
                                        <td>
                                          {item.is_active == 2 ? (
                                            <>
                                              <div className="dropdown">
                                                <a
                                                  type="button"
                                                  id="dropdownMenuButton1"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img src={IMAGES.THREE_DOTS_ICONS}
                                                    alt="3dots"
                                                  />
                                                </a>
                                                <ul
                                                  className="dropdown-menu"
                                                  style={{
                                                    zIndex: "1000",
                                                  }}
                                                  aria-labelledby="dropdownMenuButton1 "
                                                >
                                                  <li
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <a
                                                      className="dropdown-item "
                                                      onClick={() =>
                                                        handleActivateOpen(
                                                          item.id, "1"
                                                        )
                                                      }
                                                    >
                                                      Activate Account
                                                    </a>
                                                  </li>
                                                  <li
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() =>
                                                        handleDeleteOpen(
                                                          item.id, "3"
                                                        )
                                                      }
                                                    >
                                                      Delete Account
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </>
                                          ) : (



                                            <>
                                              {item.invite_status === 1 ? (
                                                <div className="dropdown">
                                                  <a
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <img
                                                      src={
                                                        IMAGES.THREE_DOTS_ICONS
                                                      }
                                                      alt="3dots"
                                                    />
                                                  </a>
                                                  <ul
                                                    className="dropdown-menu"
                                                    style={{
                                                      zIndex: "1000",
                                                    }}
                                                    aria-labelledby="dropdownMenuButton1 "
                                                  >
                                                    <li
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <a
                                                        className="dropdown-item "
                                                        onClick={() =>
                                                          handleDeactivateOpen(
                                                            item.id, "2"
                                                          )
                                                        }
                                                      >
                                                        Deactivate Account
                                                      </a>
                                                    </li>
                                                    <li
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <a
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                          handleDeleteOpen(
                                                            item.id, "3"
                                                          )
                                                        }
                                                      >
                                                        Delete Account
                                                      </a>

                                                      {(item.is_active == 1 && item.invite_status === 1 && item.is_profile === 0) ?
                                                        <li style={{ cursor: "pointer" }} ><a className="dropdown-item" onClick={() => sendInvite(item.id)}>Invite to haptapt</a></li> : ''
                                                      }
                                                    </li>
                                                  </ul>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-6 mt-2">
                        <div className="pagination-left">
                          <nav aria-label="...">
                            <ul className="custom-pagination">
                              <li
                                className="page-item active"
                                aria-current="page"
                              >
                                <span className="page-link  cus-page-item">
                                  Page
                                </span>
                              </li>
                              <li className="page-item cus-page-item">
                                <a className="page-link">
                                  {currentPage}
                                </a>
                              </li>
                              <li className="page-item cus-page-item">
                                <a className="page-link">of</a>
                              </li>
                              <li className="page-item cus-page-item">
                                <a className="page-link">

                                  {Math.ceil(
                                    getlist()?.length / PageSize
                                  )}
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                      <div className="col-6 mt-2">
                        <div className="pagination-right">
                          <nav aria-label="Page navigation example">

                            {memberList?.length > PageSize && (
                              <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={memberList?.length}
                                pageSize={PageSize}
                                onPageChange={(page) =>
                                  setCurrentPage(page)
                                }
                              />
                            )}
                          </nav>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="row align-items-center">
                      <div className="col-12 col-sm-5 col-md-4 py-2 py-lg-4">
                        <div className="list-head">
                          <h2 className="member-head mb-0">Billing details</h2>
                        </div>
                      </div>
                      <div className="col-12 col-sm-7 col-md-8 py-2 py-lg-4 text-end">
                        {/* <div className="search-category cus-search-category  new-search-category position-relative pb-2 pb-sm-0">
                          <input type="serach" placeholder="Search Member..." />
                          <img
                            src={IMAGES.SEARCH_ICON}
                            alt=""
                            className="search-icon"
                          />
                        </div> */}
                      </div>
                      <div className="col-12">
                        {billingList?.length > 0 ? (
                          <div className="table-responsive organization-table member-table thin-scroll">
                            <table className="table mb-0">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    <div class="form-check">
                                    </div>
                                  </th>
                                  <th scope="col">Invoice</th>
                                  <th scope="col">Date of Payment</th>
                                  <th scope="col">Subscription</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {billingList?.length > 0 ? (
                                  currentTableData2?.map((org) => {
                                    return (
                                      <tr className={selectedRows.includes(org.id) ? 'active' : ''}>
                                        <td>
                                          <div class="form-check">
                                            <input
                                              className="form-check-input table-check"
                                              type="checkbox"
                                              value=""
                                              id={`checkbox-${org.id}`}
                                              checked={selectedRows.includes(org.id)}
                                              onChange={() => handleRowClick1(org.id)}
                                            />
                                          </div>
                                        </td>
                                        <td style={{ cursor: "pointer" }} onClick={() => getInvoiceAPI(org.invoice_id)}>
                                          <img src={IMAGES.PDF_ICON} alt="" />
                                          #{org.start_date}
                                        </td>
                                        <td>{org.start_date}</td>
                                        <td>{org.plan_data.name}</td>
                                        <td>
                                          <div className="table-content">
                                            <p className="table-content-item item-paid">
                                              Paid
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <p className="notMember">
                                    No billing details found in this
                                    organization
                                  </p>
                                )}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <p className="notMember">
                            No billing details found in this organization
                          </p>
                        )}
                      </div>
                      <div className="col-6 mt-2">
                        <div className="pagination-left">
                          <nav aria-label="...">
                            {Math.ceil(billingList?.length / PageSize) > 0 &&
                              <ul className="custom-pagination">
                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <span className="page-link  cus-page-item">
                                    Page
                                  </span>
                                </li>
                                <li className="page-item cus-page-item">
                                  <a className="page-link">{currentPage}</a>
                                </li>
                                <li className="page-item cus-page-item">
                                  <a className="page-link">of</a>
                                </li>
                                <li className="page-item cus-page-item">
                                  <a className="page-link">
                                    {Math.ceil(billingList?.length / PageSize)}
                                  </a>
                                </li>
                              </ul>
                            }
                          </nav>
                        </div>
                      </div>
                      <div className="col-6 mt-2">
                        <div className="pagination-right">
                          <nav aria-label="Page navigation example">

                            {billingList?.length > PageSize && (
                              <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={billingList?.length}
                                pageSize={PageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                              />
                            )}
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="loader">{isLoading && <ReactLoader />}</div>

      <MemberAdded memberAddedModel={memberAddedModel} setMemberAddedModel={setMemberAddedModel} getAPI={getAPI} />
      <InviteSent invteModel={invteModel} setInviteModel={setInviteModel} getAPI={getAPI} />

      {/** Add New Member Modal */}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h1>add new member</h1>
              </div>
              <RegisterEmployee setRegisterModel={handleClose} setInviteModel={setInviteModel} setMemberAddedModel={setMemberAddedModel} organisation_id={state?.id}/>
            </div>
          </div>
        </Box>
      </Modal>


      <Modal
        open={openDeactivate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleDeactivateClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>deactivate account</h2>
              </div>
              <div className="mb-3 mb-lg-4 position-relative text-center">
                <p>
                  Are you certain that you want to proceed with deleting the account
                  from the platform?
                </p>
              </div>
              <div
                className="mb-3 mb-sm-4 text-center position-relative submit-head"
                onClick={getActionApi1}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Deactivate{" "}
                  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleDeleteClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>delete account</h2>
              </div>
              <div className="mb-4  position-relative text-center">
                <p>
                  Are you certain that you want to proceed with deleting the account
                  from the platform?
                </p>
              </div>
              <div
                className="mb-5 text-center position-relative submit-head"
                onClick={getActionApi1}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Delete <img src={IMAGES.ARROW_GREY} className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openOrgDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleDeleteOrgClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>delete account</h2>
              </div>
              <div className="mb-4  position-relative">
                <p>
                  Are you certain that you want to proceed with deleting the account
                  from the platform?
                </p>
              </div>
              <div
                className="mb-5 text-center position-relative submit-head"
                onClick={getActionDeleteApi}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Delete{" "}
                  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>


      <Modal
        open={editProfle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleEditProfileClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h1>edit profile</h1>
              </div>
              <form onSubmit={postAPIEditProfile}>
                <div className="mb-4 mb-xxl-5 position-relative mx-5">
                  <TextField
                    fullWidth
                    label="Your name"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" },
                    }}
                    name="name"
                    onChange={handleChange2}
                    value={fields1.name}
                  />
                  {errors.name && <span className="err_msg">{errors.name}</span>}
                </div>
                <div className="mb-4 mb-xxl-5 position-relative mx-5">
                  <label className="form-label email-label mb-0 email-input">
                    Email address
                  </label>
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange2}
                    error={errors.email}
                    value={fields1.email}
                    className="form-control comm-radius comm-input"
                    disabled
                  />
                </div>
                <div className="mb-4 mb-xxl-5 position-relative mx-5">
                  <LoadScript
                    googleMapsApiKey={key}
                    libraries={libraries}
                  >

                    <StandaloneSearchBox
                      ref={inputRef1}
                      onLoad={(ref) => (inputRef1.current = ref)}
                      onPlacesChanged={handlePlaceChanged}
                    >
                      <TextField
                        fullWidth
                        label="Location"
                        variant="outlined"
                        sx={{
                          "& fieldset": {
                            border: "2px solid #231f20",
                            borderRadius: "30px",
                          },
                          "& .MuiInputLabel-shrink": {
                            color: "#231f20;",
                          },
                        }}
                        name="place"
                        onChange={handleChange2}
                        value={fields1.place}
                        placeholder="Enter location"
                      />
                    </StandaloneSearchBox>
                  </LoadScript>
                  {errors?.place && <span className="err_msg">{errors.place}</span>}
                </div>
                <div className="mb-4 mb-xxl-5 position-relative mx-5">
                  <TextField
                    fullWidth
                    label=" Role/Title"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" },
                    }}
                    name="roleTitle"
                    onChange={handleChange2}
                    value={fields1.roleTitle}
                  />
                  {errors.roleTitle && <span className="err_msg">{errors.roleTitle}</span>}
                </div>
                <div className="mb-4 mb-xxl-5 text-center position-relative submit-head">
                  <button
                    type="submit"
                    className="yellow-btn d-flex align-items-center"
                  >
                    Save{" "}
                    <img
                      src={IMAGES.ARROW_GREY}
                      alt=""
                      className="img-fluid ms-2"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openActivate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleActivateClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>Activate account</h2>
              </div>
              <div className="mb-4  position-relative text-center">
                <p>
                  Are you certain that you want to proceed with activating the
                  account ?
                </p>
              </div>
              <div
                className="mb-5 text-center position-relative submit-head"
                onClick={getActionApi1}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Activate{" "}
                  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openOrgDeactivate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleOrgDeactivateClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>Deactivate account</h2>
              </div>
              <div className="mb-3 mb-lg-4 position-relative text-center">
                <p>
                  Are you certain that you want to proceed with deleting the account
                  from the platform?
                </p>
              </div>
              <div
                className="mb-4 mb-lg-5 text-center position-relative submit-head"
                onClick={getActionApi}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Deactivate{" "}
                  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer rtl />

        <Modal
          open={editOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box  className="modal modal-custom">
            <div className="modal-dialog modal-dialog-centered modal-dialog-width">
              <div className="modal-content modal-content-custom">
                <div onClick={handleEditClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                  <img src={IMAGES.CROSS} />
                </div>
                <div className="signin-head text-center pb-3">
                  <h1>Edit Category</h1>
                </div>
                <div className="mb-4  position-relative px-3">
                  <p>
                    Please modify the category name and its associated options for input on the platform.                        </p>
                </div>
                <form onSubmit={postAPIEditCat} >
                  <div className="editbottom position-relative mx-4 mx-sm-5">
                    <TextField fullWidth label="Enter category name" variant="outlined"

                      sx={{
                        "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                        "& .MuiInputLabel-shrink": { color: "#231f20;" }
                      }}
                      name="category" onChange={handleChange3} value={fields2.category} />
                    {errors.category && <span className="err_msg">{errors.category}</span>}
                  </div>

                  <div className="editbottom position-relative mx-4 mx-sm-5">

                    <Autocomplete
                      clearIcon={<ClearIcon
                        onClick={(e) => {
                          setFields2({
                            ...fields2,
                            subCatArr: [],
                            subCat: ""
                          });
                          setErrors("")
                        }}
                        fontSize='small' />}
                      multiple
                      options={[]}
                      name='subCatArr'
                      defaultValue={fields2.subCatArr}
                      value={fields2.subCatArr}
                      onChange={(event, newValue) => {
                        setErrors("")
                        setFields2({
                          ...fields2,
                          subCatArr: newValue,
                        });
                      }}
                      inputValue={fields2.subCat}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip label={option}
                            sx={{
                              backgroundColor: "#EBFBFF",
                              color: "#231F20",
                              "& .MuiChip-deleteIcon": {
                                color: ""
                              }
                            }}
                            {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label="Enter options" variant="outlined"
                          onBlur={(e) => {
                            if (e.target.value.trim().length != 0 && fields2.subCatArr.includes(e.target.value) === false) {
                              setFields({
                                ...fields2,
                                subCatArr: [...fields2.subCatArr, e.target.value],
                                subCat: ""
                              })
                            }
                            return;

                          }}
                          onKeyDownCapture={(e) => {
                            if (e.code === "Enter" || e.code === "NumpadEnter" || e.key === ",") {
                              e.preventDefault();
                              const trimmedValue = e.target.value.trim();
                              if (trimmedValue.length > 0 && !fields2.subCatArr.includes(trimmedValue)) {
                                setFields2({
                                  ...fields2,
                                  subCatArr: [...fields2.subCatArr, trimmedValue],
                                  subCat: ""
                                });
                              }
                              e.target.value = "";
                            }
                          }}

                          sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                          }}
                          name="subCat" onChange={handleChange3} value={fields2.subCat} />
                      )}
                    />
                    {errors.subcatArr && <span className="err_msg">{errors.subcatArr}</span>}
                  </div>

                  <div className="text-center mb-5 position-relative submit-head">
                    <button type="submit" className="yellow-btn d-flex align-items-center">Update <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Modal>
      <Modal
        open={catOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div onClick={handleCatClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h1>add new category</h1>
              </div>
              <div className="mb-4  position-relative px-3">
                <p>
                  Please provide the name of the category and its corresponding options for the platform's input.
                </p>
              </div>
              <form onSubmit={postCatAPI} >
                <div className="editbottom position-relative mx-4 mx-sm-5">

                  <TextField fullWidth label="Enter category name" variant="outlined"

                    sx={{
                      "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" }
                    }}
                    name="category" onChange={handleChange3} value={fields2.category} />
                  {errors.category && <span className="err_msg">{errors.category}</span>}
                </div>

                <div className="editbottom position-relative mx-4 mx-sm-5">

                  <Autocomplete
                    clearIcon={<ClearIcon
                      onClick={(e) => {
                        setFields2({
                          ...fields2,
                          subCatArr: [],
                          subCat: ""
                        });
                        setErrors("")
                      }}
                      fontSize='small' />}
                    multiple
                    options={[]}
                    name='subCatArr'
                    value={fields2.subCatArr}
                    onChange={(event, newValue) => {
                      setErrors("")
                      setFields2({
                        ...fields2,
                        subCatArr: newValue,
                      });
                    }}
                    inputValue={fields2.subCat}

                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => (
                        <Chip label={option}
                          sx={{
                            backgroundColor: "#EBFBFF",
                            color: "#231F20",
                            "& .MuiChip-deleteIcon": {
                              color: ""
                            }
                          }}
                          {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField

                        fullWidth
                        {...params}
                        label="Enter options" variant="outlined"
                        onBlur={(e) => {
                          setErrors("")
                          if (e.target.value.trim().length != 0 && fields2.subCatArr.includes(e.target.value) === false) {
                            setFields2({
                              ...fields2,
                              subCatArr: [...fields2.subCatArr, e.target.value],
                              subCat: ""
                            })
                          }
                          return;

                        }}
                        onKeyDownCapture={(e) => {
                          setErrors("")
                          if (e.code == "Enter" || e.code == "NumpadEnter" || e.key == ",") {

                            if (e.target.value.trim().length != 0 && fields2.subCatArr.includes(e.target.value) === false) {
                              e.preventDefault();
                              setFields2({
                                ...fields2,
                                subCatArr: [...fields2.subCatArr, e.target.value],
                                subCat: ""
                              })
                            }
                            else if (e.target.value.trim().length == 0) {
                              setFields2({
                                ...fields2,
                                subCatArr: [...fields2.subCatArr],
                                subCat: ""
                              })
                            }

                          }
                          return;


                        }}
                        sx={{
                          "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                          "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                        name="subCat" onChange={handleChange3} value={fields2.subCat} />
                    )}
                  />
                  {errors.subcatArr && <span className="err_msg">{errors.subcatArr}</span>}

                </div>

                <div className="text-center mb-5 position-relative submit-head" style={{ cursor: "pointer" }}>
                  <button type="submit" className="yellow-btn d-flex align-items-center">Create <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default OrganizationMember;
