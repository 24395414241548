
import React, { useEffect, useState, useRef, cloneElement } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IMAGES from '../../Middleware/images';
import "../../Assets/Styles/profile.css"
import ERR_MESSAGE from '../../Helper/error-helper';
import apiHelper from '../../Helper/api-helper';
import ReactLoader from '../../ControlComponents/react-loader';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Helmet } from 'react-helmet';
import titles from '../../Helper/title';
import { Autocomplete } from '@mui/material';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useContext } from 'react';
import { GlobalContext } from '../../App';
const libraries = ['places'];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const UserProfile = () => {
    const [openDelete, setOpenDelete] = useState(false);
    const [deltId, setDeltId] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(false)
    const [errors, setErrors] = useState("");
    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [eye_icon1, setEye_icon1] = useState(IMAGES.EYE_HIDE);
    const [type1, setType1] = useState("password");
    const [msg1, setMsg1] = useState("reveal password")
    const [eye_icon2, setEye_icon2] = useState(IMAGES.EYE_HIDE);
    const [type2, setType2] = useState("password");
    const [msg2, setMsg2] = useState("reveal password")
    const [eye_icon3, setEye_icon3] = useState(IMAGES.EYE_HIDE);
    const [type3, setType3] = useState("password");
    const [state, setState] = useState(0)
    const [msg3, setMsg3] = useState("reveal password")
    const [fields1, setFields1] = useState({
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
        email: "",
        name: "",
        place: "",
        longitude: "",
        latitude: "",
        roleTitle: "",
        category: "",
        subCat: "",
        subCatArr: [],
        previousSubCatId: [],
        previousSubCat: []
    })
    const inputRef = useRef(null);
    let key = "AIzaSyDFkw6-U8mAQEGTOC77ZYrIojD4isGoNgg";
    const handlePlaceChanged = () => {
        setErrors("")
        const [place] = inputRef?.current?.getPlaces();

        if (place) {
            setFields1({
                ...fields1,
                place: place.formatted_address,
                latitude: `${place.geometry.location.lat()}`,
                longitude: `${place.geometry.location.lng()}`,
            });
        }
    };
    const [categoryList, setCategoryList] = useState([]);
    const [editId, setEditId] = useState(null);
    const autocompleteRef = React.useRef();

    const [superAdminDetail, setSuperAdminDetail] = useState("")
    const handleDeleteOpen = (id) => {
        document.body.classList.add("modal-open");
        setDeltId(id)
        setOpenDelete(true);
    }

    const handleDeleteClose = () => {
        document.body.classList.remove("modal-open");
        setOpenDelete(false);
        setDeltId(null)
    }

    const handleAutocompleteClick = () => {
        autocompleteRef.current.focus();
        setOpenDropdown(true)
    };

    async function getDeleteAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest(`auth/delete-category?id=${deltId}`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false)
            success(result.message)
            handleDeleteClose()
            getMyDetailAPI();
            getAPI();
        }
        else {
            error(result.message)
            setLoading(false)
        }
    }

    const handleOpen = () => {
        document.body.classList.add("modal-open");
        setFields1({
            ...fields1,
            latitude: superAdminDetail.latitude,
            longitude: superAdminDetail.longitude,
            name: superAdminDetail.name,
            place: superAdminDetail.location,
            email: superAdminDetail.email,
            roleTitle: superAdminDetail.designation
        })
        setOpen(true);
    }
    const handleClose = () => {
        document.body.classList.remove("modal-open");
        setErrors("")
        setOpen(false);
    }
    const [subCategory, setSubCategoryData] = useState([])

    const handleEditOpen = (id, catName, subcatdata) => {
        document.body.classList.add("modal-open");
        setSubCategoryData(subcatdata)
        const selectedNames = subcatdata
            .filter(item => item.selected !== null)
            .map(item => item.name);

        const selectedIds = subcatdata
            .filter(item => item.selected !== null)
            .map(item => item.id);
        setEditId(id)
        setEditOpen(true);
        setFields1({
            category: catName,
            subCat: "",
            subCatArr: selectedNames,
            previousSubCat: subcatdata?.map(item => item.name),
            previousSubCatId: selectedIds
        })
        localStorage.setItem('subCatArr', JSON.stringify(selectedNames));

    }

    const handleEditClose = () => {
        document.body.classList.remove("modal-open");
        localStorage.removeItem('subCatArr');
        setErrors("")
        setFields1(
            {
                category: "",
                subCat: "",
                subCatArr: [],
                previousSubCat: [],
                previousSubCatId: []
            }
        )
        setEditOpen(false);
    }

    const handleChangePasswordOpen = () => {
        document.body.classList.add("modal-open");
        setOpenChangePassword(true);
    }

    const handleChangePasswordClose = () => {
        document.body.classList.remove("modal-open");
        setFields1(
            {
                ...fields1,
                oldPassword: "",
                newPassword1: "",
                newPassword2: "",
            }
        )
        setErrors("");
        setOpenChangePassword(false)
    };



    const showOldPassword = () => {
        if (type3 === "password") {
            setType3("text");
            setEye_icon3(IMAGES.EYE_SHOW);
            setMsg3("hide password")
        }
        else {
            setType3("password");
            setEye_icon3(IMAGES.EYE_HIDE);
        }
    }
    const showPassword = () => {
        if (type1 === "password") {
            setType1("text");
            setEye_icon1(IMAGES.EYE_SHOW);
            setMsg1("hide password")
        }
        else {
            setType1("password");
            setEye_icon1(IMAGES.EYE_HIDE);
        }
    }
    const showConfirmPassword = () => {
        if (type2 === "password") {
            setType2("text");
            setEye_icon2(IMAGES.EYE_SHOW);
            setMsg2("hide password")
        }
        else {
            setType2("password");
            setEye_icon2(IMAGES.EYE_HIDE);
        }
    }

    const handleChange = (e) => {
        setErrors("");
        const name = e.target.name;
        const value = e.target.value;

        if (name === "place") {
            setFields1({
                ...fields1,
                [name]: value,
                latitude: "",
                longitude: ""
            });
        }
        else {
            setFields1({
                ...fields1,
                [name]: value,
            });
        }

    };

    const handleChange2 = (e) => {
        setErrors("");
        const name = e.target.name;
        const value = e.target.value;
        setFields1({
            ...fields1,
            [name]: value,
        });
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
        const { oldPassword, newPassword1, newPassword2 } = fields1;

        if (oldPassword?.trim() == "") {
            formIsValid = false
            errors["oldPassword"] = ERR_MESSAGE.OLD_PASSWORD_EMPTY
        } else if (passwordReg.test(oldPassword) === false) {
            formIsValid = false
            errors["oldPassword"] = ERR_MESSAGE.INVALID_PASSWORD
        }
        if (newPassword1?.trim() == "") {
            formIsValid = false
            errors["newPassword1"] = ERR_MESSAGE.NEW_PASSWORD_EMPTY
        } else if (passwordReg.test(newPassword1) === false) {
            formIsValid = false
            errors["newPassword1"] = ERR_MESSAGE.INVALID_PASSWORD
        }
        if (newPassword2?.trim() == "") {
            formIsValid = false
            errors["newPassword2"] = ERR_MESSAGE?.CONFIRM_PASSWORD_EMPTY
        }
        else if (newPassword2?.trim() !== newPassword1?.trim()) {
            formIsValid = false
            errors["newPassword2"] = ERR_MESSAGE.NOTMATCHED
        }
        setErrors(errors);
        return formIsValid;
    };


    const handleValidation2 = () => {
        let errors = {};
        let formIsValid = true;

        const { category, subCatArr } = fields1;
        if (!category || category.trim().length === 0) {
            formIsValid = false;
            errors["category"] = ERR_MESSAGE.CATEGORY_EMPTY
        }
        // else if (!subCatArr || subCatArr.length === 0) {
        //     formIsValid = false;
        //     errors["subCatArr"] = ERR_MESSAGE.OPTIONS_EMPTY
        // }
        setErrors(errors);
        return formIsValid;
    };

    const handleValidationUserProfile = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const emojiSymbolRegex = /[^\p{L}\s]/u;
        const { email, name, place, roleTitle, longitude, latitude } = fields1;
        const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
        if (!name || name.trim().length === 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.EMPTY_NAME
        } else if (name.length < 3) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_NAME_MIN
        }
        else if (name.length > 30) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_NAME_MAX
        }
        else if (emojiRegex.test(name)) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_NAME;
        }
        if (!email || email.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
        }
        else if (reg.test(email.toLowerCase()) === false) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID
        }
        if (!place || place.trim().length === 0) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.LOCATION_EMPTY
        }
        else if (!longitude || longitude.trim().length === 0 || !latitude || latitude.trim().length === 0) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.INVALID_LOCATION;
        }
        else if (emojiRegex.test(place)) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.EMOJI;
        }
        if (!roleTitle || roleTitle.trim().length === 0) {
            formIsValid = false;
            errors["roleTitle"] = ERR_MESSAGE.ROLE_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    };
    const success = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }
    const error = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }

    async function postAPI(e) {
        e.preventDefault();
        if (handleValidation()) {
            let data = JSON.stringify({
                "oldPassword": fields1.oldPassword,
                "newPassword": fields1.newPassword2,
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/change-passsword", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleChangePasswordClose()
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }

    async function postAPIEditUserProfile(e) {
        e.preventDefault();
        if (handleValidationUserProfile()) {
            let data = JSON.stringify({
                "name": fields1.name,
                "designation": fields1.roleTitle,
                "id": localStorage.getItem("id"),
                "location": fields1.place,
                "latitude": fields1.latitude,
                "longitude": fields1.longitude
            })
            setLoading(true);
            let result = await apiHelper.postRequest("auth/set-your-profile", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                getMyDetailAPI();
                setLoading(false);
                success(result.message)
                handleClose();
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }

    async function postAPIEditCat(e) {
        e.preventDefault();
        const selectedIds = [];
        const storedSubCatArr = localStorage.getItem('subCatArr');
        const newStore = JSON.parse(storedSubCatArr)
        newStore.forEach(subCat => {
            const matchingSubCategory = subCategory.find(item => item.name === subCat);
            if (matchingSubCategory) {
                selectedIds.push(matchingSubCategory.id);
            }
        });
        if (handleValidation2() && selectedIds) {
            let data = JSON.stringify({
                "changedfrom": fields1.previousSubCatId,
                "changedto": selectedIds
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/update-category", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleEditClose();
                getAPI()
                localStorage.removeItem('subCatArr');
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }

    async function getMyDetailAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("auth/get-my-detail")
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setSuperAdminDetail(result.body)
            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }

    async function getAPI() {
        setLoading(true);
        let result = await apiHelper.getRequest("auth/category");
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setCategoryList(result?.body);
            if (editId) {
                const matchedCategory = result.body.find(category => category.id === editId);
                if (matchedCategory) {
                    const { name, osubcatdata } = matchedCategory;
                    const selectedIds = osubcatdata
                        .filter(item => item.selected !== null)
                        .map(item => item.id);
                    const storedSubCatArr = localStorage.getItem('subCatArr');
                    const newStore = JSON.parse(storedSubCatArr)
                    setFields1({
                        category: name,
                        subCat: "",
                        subCatArr: newStore,
                        previousSubCat: osubcatdata?.map(item => item.name),
                        previousSubCatId: selectedIds
                    });
                    setSubCategoryData(osubcatdata)
                }
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    }


    useEffect(() => {
        getMyDetailAPI();
        getAPI();
    }, [])

    async function textApi(value) {
        let data = {
            "name": value,
            "cat_id": editId
        };
        setLoading(true);
        try {
            let result = await apiHelper.postRequest("auth/create-subcategory", JSON.stringify(data));
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                const newName = result?.body?.name;
                const storedArrayString = localStorage.getItem("subCatArr");
                const storedArray = JSON.parse(storedArrayString);
                const newValues = [...storedArray, newName];
                localStorage.setItem("subCatArr", JSON.stringify(newValues))
                getAPI()
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleKeyDown = (event) => {
        const { key, target } = event;
        if ((key === 'Enter' || key === ',' || event.key === "NumpadEnter") && target.value.trim().length > 0) {
            event.preventDefault();
            const value = target.value.trim();
            if (!fields1?.previousSubCat.includes(value.toLowerCase())) {
                textApi(value);
                setOpenDropdown(true)
            }
            else {
                const storedSubCatArr = JSON.parse(localStorage.getItem('subCatArr'))
                const newValues = [...storedSubCatArr, value];
                if (fields1?.previousSubCat.includes(value.toLowerCase())) {
                    if (storedSubCatArr.includes(value.toLowerCase())) {
                        const filterData = storedSubCatArr.filter((item) => item != value.toLowerCase())
                        setFields1({
                            ...fields1,
                            subCatArr: filterData,
                        })
                        localStorage.setItem("subCatArr", JSON.stringify(filterData))
                    } else {
                        setFields1({
                            ...fields1,
                            subCatArr: newValues,
                        })
                        localStorage.setItem("subCatArr", JSON.stringify(newValues))
                    }
                }
                setOpenDropdown(true)
            }
        }
    }


    return (
        <>
            <Helmet>
                <title>{titles.profile}</title>
            </Helmet>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <section className="pt-5">
                <div className="container">
                    <div className="row align-items-center pb-4">

                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-3">
                            <div className="UserProfile-heading">
                                <h2>Your Profile</h2>
                            </div>
                            <div className="company-info back-shadow">
                                <div className="p-4">
                                    <div className="company-section-sub-head d-flex justify-content-between py-3 ps-xxl-4 pe-xxl-0 align-items-center">
                                        <h2 className="company-info-head mb-0">{superAdminDetail?.name?.replace(/-/g, ' ')}
                                            {/* <p className="user-professtion">Profession</p> */}
                                        </h2>
                                        <img onClick={handleOpen} style={{ cursor: "pointer" }} src={IMAGES.EDIT_ICON} alt="edit-icon" className="img-fluid" />
                                    </div>
                                    <ul className="sub-company-list mb-2">
                                        <li className="sub-company-list-item">
                                            <label className="sq-radio">
                                                <input type="checkbox" checked="checked" name="radio" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className='label-profile'>
                                                <p className="pe-1 label-head">Email :</p>
                                                <p className="fw-900 one-line-text text-cus-align">{superAdminDetail.email}</p>
                                            </div>
                                        </li>
                                        <li className="sub-company-list-item">
                                            <label className="sq-radio">
                                                <input type="checkbox" checked="checked" name="radio" />
                                                <span className="checkmark"></span>
                                            </label>

                                            <div className='label-profile'>
                                                <p className="pe-1 label-head">Location :</p>
                                                <p className="fw-900 one-line-text text-cus-align">{superAdminDetail.location}</p>
                                            </div>

                                        </li>
                                        <li className="sub-company-list-item">
                                            <label className="sq-radio">
                                                <input type="checkbox" checked="checked" name="radio" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className='label-profile'>
                                                <p className="pe-1 label-head">Role :</p>
                                                <p className="fw-900 one-line-text text-cus-align" style={{ textTransform: "capitalize" }}>{superAdminDetail.designation}</p>
                                            </div>
                                        </li>
                                        <li className="sub-company-list-item">
                                            <label className="sq-radio">
                                                <input type="checkbox" checked="checked" name="radio" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className='label-profile'>
                                                <p className="pe-1 label-head">User Type :</p>
                                                <p className="fw-900 one-line-text text-cus-align">{superAdminDetail.role_name}</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="mx-auto text-center" style={{ cursor: "pointer" }}>
                                        <div onClick={handleChangePasswordOpen} className="company-info-btns position-relative mt-4">
                                            <button type="button" className="yellow-btn add-category-btn d-flex align-items-center yellow-change-btn">Change Password  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-9 pt-4 pt-xl-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="category-list">
                                        <ul>
                                            {
                                                categoryList.map((item) => {
                                                    const filteredSubcategories = item.osubcatdata?.filter(subcat => subcat.selected !== null);

                                                    return (
                                                        <li key={item.id} >
                                                            <div className="list-category-main d-flex justify-content-between align-items-center">
                                                                <div className="list-category-left">
                                                                    <div className="list-category-head">
                                                                        <p>{item.name}</p>
                                                                    </div>
                                                                    <div className="list-content mt-3">
                                                                        {
                                                                            filteredSubcategories?.map((subcat) => {
                                                                                return (
                                                                                    <p key={subcat.id} className="list-content-item mb-2 mb-xxl-3">{subcat.name}</p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div style={{ cursor: "pointer" }} className="list-category-right">
                                                                    {/* <img onClick={() => handleDeleteOpen(item.id)} src={IMAGES.TRASH_ICON} alt="delete-icon" style={{ cursor: "pointer" }} className="img-fluid" /> */}
                                                                    <img onClick={() => handleEditOpen(item.id, item.name, item?.osubcatdata)} src={IMAGES.EDIT_ICON} alt="edit-icon" className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box  className="modal modal-custom">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                        <div className="modal-content modal-content-custom">
                            <div onClick={handleClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                                <img src={IMAGES.CROSS} />
                            </div>
                            <div className="signin-head text-center pb-3">
                                <h1 >edit your profile</h1>
                            </div>
                            <form onSubmit={postAPIEditUserProfile}>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <TextField fullWidth label="Your name" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="name" onChange={handleChange} value={fields1.name} />
                                    {errors.name && <span className="err_msg">{errors.name}</span>}
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <label className="form-label email-label mb-0 email-input">Email address</label>
                                    <input type="text" name="email" onChange={handleChange} error={errors.email}
                                        value={fields1.email} className="form-control comm-radius comm-input" disabled />
                                    {errors.email && <span className="err_msg">{errors.email}</span>}
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <LoadScript
                                        googleMapsApiKey={key}
                                        libraries={libraries}
                                    >

                                        <StandaloneSearchBox
                                            ref={inputRef}
                                            onLoad={(ref) => (inputRef.current = ref)}
                                            onPlacesChanged={handlePlaceChanged}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Location"
                                                variant="outlined"
                                                sx={{
                                                    "& fieldset": {
                                                        border: "2px solid #231f20",
                                                        borderRadius: "30px",
                                                    },
                                                    "& .MuiInputLabel-shrink": {
                                                        color: "#231f20;",
                                                    },
                                                }}
                                                name="place"
                                                onChange={handleChange}
                                                value={fields1.place}
                                                placeholder="Enter location"
                                            />
                                        </StandaloneSearchBox>
                                    </LoadScript>
                                    {errors?.place && <span className="err_msg">{errors.place}</span>}
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5">
                                    <TextField fullWidth label=" Role/Title" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="roleTitle" onChange={handleChange} value={fields1.roleTitle} />
                                    {errors.roleTitle && <span className="err_msg">{errors.roleTitle}</span>}
                                </div>
                                <div className="mb-5 text-center position-relative submit-head">
                                    <button type="submit" className="yellow-btn d-flex align-items-center">Save <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openChangePassword}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box  className="modal modal-custom">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                        <div className="modal-content modal-content-custom">
                            <div onClick={handleChangePasswordClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                                <img src={IMAGES.CROSS} />
                            </div>
                            <div className="signin-head text-center pb-3">
                                <h1>change password</h1>
                            </div>
                            <form onSubmit={postAPI}>
                                <div className="editbottom position-relative mx-4 mx-sm-5 forgot-input">
                                    <TextField type={type3} fullWidth label="Old password" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="oldPassword" onChange={handleChange2} value={fields1.oldPassword} />
                                    <img src={eye_icon3} alt="" onClick={showOldPassword} className="hiden-eye" title={msg3} />
                                    {errors.oldPassword && <span className="err_msg">{errors.oldPassword}</span>}
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5 forgot-input">
                                    <TextField type={type1} fullWidth label="New password" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="newPassword1" onChange={handleChange2} value={fields1.newPassword1} />

                                    <img src={eye_icon1} alt="" onClick={showPassword} className="hiden-eye" title={msg1} />
                                    {errors.newPassword1 && <span className="err_msg">{errors.newPassword1}</span>}
                                </div>
                                <div className="editbottom position-relative mx-4 mx-sm-5 forgot-input">
                                    <TextField type={type2} fullWidth label="Confirm password" variant="outlined"
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                        name="newPassword2" onChange={handleChange2} value={fields1.newPassword2} />

                                    <img src={eye_icon2} alt="" onClick={showConfirmPassword} className="hiden-eye" title={msg2} />
                                    {errors.newPassword2 && <span className="err_msg">{errors.newPassword2}</span>}
                                </div>
                                <div className="editbottom text-center position-relative submit-head">
                                    <button type="submit" className="yellow-btn d-flex align-items-center">Save <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>

                <Modal
                    open={editOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box  className="modal modal-custom">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                            <div className="modal-content modal-content-custom">
                                <div onClick={handleEditClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                                    <img src={IMAGES.CROSS} />
                                </div>
                                <div className="signin-head text-center pb-3">
                                    <h1>Edit Category</h1>
                                </div>
                                <div className="mb-4  position-relative px-3">
                                    <p>
                                        Please modify the category name and its associated options for input on the platform. </p>
                                </div>
                                <form onSubmit={postAPIEditCat}>
                                    <div className="editbottom position-relative mx-4 mx-sm-5">
                                        <TextField fullWidth label="Enter category name" variant="outlined"
                                            sx={{
                                                "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                                "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                            }}
                                            name="category" value={fields1.category} aria-readonly />
                                        {errors.category && <span className="err_msg">{errors.category}</span>}
                                    </div>
                                    <div className="editbottom position-relative mx-4 mx-sm-5">
                                        <Autocomplete
                                            ref={autocompleteRef}
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={fields1.previousSubCat}
                                            disableCloseOnSelect
                                            value={fields1.subCatArr}
                                            getOptionLabel={(option) => option}
                                            open={openDropdown}

                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option}
                                                </li>
                                            )}
                                            style={{ width: 500 }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Options"
                                                    onKeyDown={handleKeyDown}
                                                    onFocus={() => setOpenDropdown(true)}
                                                    onBlur={() => setOpenDropdown(false)}
                                                    onChange={(e) => {
                                                        setErrors("")
                                                        const value = e.target.value;
                                                        setOpenDropdown(fields1.subCatArr.some(item => item === value));
                                                    }}
                                                    onClick={() => setOpenDropdown(true)}
                                                    InputProps={{ ...params.InputProps, inputProps: { ...params.inputProps, inputMode: 'search' } }}
                                                />
                                            )}

                                            onChange={(event, newValue) => {
                                                setErrors("");
                                                setFields1({
                                                    ...fields1,
                                                    subCatArr: newValue,
                                                });
                                                setOpenDropdown(true);
                                                localStorage.setItem('subCatArr', JSON.stringify(newValue));

                                            }}
                                            onClick={handleAutocompleteClick}
                                            className="custom-autocomplete"
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                        />

                                        {errors.subCatArr && <span className="err_msg">{errors.subCatArr}</span>}
                                    </div>

                                    <div className="text-center mb-5 position-relative submit-head">
                                        <button type="submit" className="yellow-btn d-flex align-items-center">Update <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Box>
                </Modal>

            <Modal
                open={openDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box  className="modal modal-custom">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                        <div className="modal-content modal-content-custom">
                            <div onClick={handleDeleteClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                                <img src={IMAGES.CROSS} />
                            </div>
                            <div className="signin-head text-center pb-3">
                                <h2>delete category</h2>
                            </div>
                            <div className="mb-4  position-relative">
                                <p>
                                    Are you sure you want to delete the category name and its associated tags?
                                </p>
                            </div>
                            <div className="mb-5 text-center position-relative submit-head">
                                <button type="submit" className="yellow-btn d-flex align-items-center" onClick={getDeleteAPI}>Delete <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>

            <ToastContainer rtl />
        </>
    )
}

export default UserProfile;