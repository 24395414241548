import React, { useEffect, useRef, useState } from 'react'
import IMAGES from '../Middleware/images'
import ROUTES from '../Middleware/routes'
import { useNavigate, NavLink, Link, useLocation } from 'react-router-dom'
import DEVELOPMENT_CONFIG from "../Helper/config"
import apiHelper from '../Helper/api-helper';
import { useClickOutside } from '../CustomHook/useClickOutside';

import { useQuery } from 'react-query';
function HeaderUser() {
    const navigate = useNavigate()
    const location = useLocation()
    const [notifications, setNotifications] = useState([]);
    const [notificationDropdown, setNotificationDropdown] = useState(false);
    const navRef = useRef();
    const notificationRef = useRef();
    const iconRef = useRef();
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("role")
        localStorage.removeItem("id")
        localStorage.removeItem("updatedArray")
        navigate(ROUTES.SIGN_IN)
    }
    const handleRouting = () => {
        if (navRef.current.classList.contains("show")) {
            navRef.current.classList.remove("show");
        }
    }

    const handleClickOutside = () => {
        setNotificationDropdown(false);
    };

    useClickOutside([notificationRef, iconRef], handleClickOutside);

    async function getAPI() {
        let result = await apiHelper.getRequest(`organization/get-notification`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setNotifications(result.body)
        }
        else {

        }
    }

    async function getAPI2(id, senderId) {
        console.log(senderId, "id")
        let result = await apiHelper.getRequest(`organization/view-notification?id=${id}`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {

            handleNavigate(senderId)

        }
        else {
        }

    }


    const isNavLinkActive = (path) => {
        return location.pathname === path;
    };
    const handleNavigate = (id) => {
        setNotificationDropdown(!notificationDropdown)
        navigate(ROUTES.PROFILE_COMPONENT, { state: { id } })
    }
    const { data } = useQuery('notifications', getAPI, { refetchInterval: 5000 })


    return (
        <>
            <nav className="navbar navbar-expand-lg yellow-back navbar-header pb-2">
                <div className="container yellow-back ">
                    <NavLink to={ROUTES.SEARCH_DATABASE} onClick={handleRouting} className="navbar-brand p-0"><img src={IMAGES.HAPTAPT_BLACK} className="img-fluid" alt="haptapt-logo" width={178} height={56} /></NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div ref={navRef} className="collapse navbar-collapse yellow-back mobile-dropdown pb-2" id="navbarNavDropdown" style={{ alignItems: 'self-start' }}>
                        <ul className="navbar-nav ms-auto navbar-item-head">
                            {
                                location.pathname == "/user" ? <li onClick={handleRouting} className="nav-item me-3" style={{ cursor: "pointer" }}>
                                    <NavLink to={ROUTES.SEARCH_DATABASE2} className="nav-link py-3 " aria-current="page">Search Members</NavLink>                                 </li>
                                    : <li onClick={handleRouting} className="nav-item me-3" style={{ cursor: "pointer" }}>
                                        <NavLink to={ROUTES.SEARCH_DATABASE} className="nav-link py-3 " aria-current="page">Search Members</NavLink>
                                    </li>
                            }
                        </ul>
                        <ul className='pe-3 mobile-dropdown d-mobile-dropdowns' style={{ position: 'relative', top: '10px' }}>
                            <li className="nav-item me-2" style={{ cursor: "pointer" }}>

                                <div className="dropdown nav-link position-relative">
                                    <div ref={iconRef} onClick={() => setNotificationDropdown(!notificationDropdown)}><img src={IMAGES.NOTIFICATION} className="img-fluid" alt="notfy-cus" /></div>
                                    {notificationDropdown && (
                                        <div ref={notificationRef} className={notifications.length > 0 ? "dropdown-menu notify-dropdown show droptrangle " : "dropdown-menu notify-dropdown show droptrangle dropnotify"}>
                                            <ul>
                                                {notifications.length > 0 ? (
                                                    notifications.slice().reverse().map(notification => (
                                                        <li key={notification.id} onClick={() => getAPI2(notification.id, notification.sender_id)}>
                                                            <Link className={notification.is_view === 0 ? "dropdown-item tapped-drop-item notificition-drop-items active" : "dropdown-item tapped-drop-item notificition-drop-items"}>
                                                                <div className="notify-profile-list d-flex align-items-center gap-3" onClick={() => handleNavigate(notification.sender_id)}>
                                                                    <div className="notify-profile-head" style={{ textTransform: "uppercase" }}>
                                                                        <span className="notify-profile">{notification?.message?.split(' ')[5]?.substr(0, 2)}</span>
                                                                    </div>
                                                                    <div className="notify-text">
                                                                        <p>{notification.message}<br />
                                                                            <div className='d-flex align-items-center'>
                                                                                {new Date(notification.created_at).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                                                                                <span className='dot-span'></span>
                                                                                <span>{new Date(notification.created_at).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
                                                                            </div>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li className='px-4'>No notifications.</li>
                                                )}
                                            </ul>
                                        </div>
                                    )}

                                </div>
                            </li>
                            <li className="nav-item" style={{ cursor: "pointer" }}>
                                <div className="dropdown nav-link position-relative">
                                    <a className="" role="button" data-bs-toggle="dropdown" aria-expanded="false"><img src={isNavLinkActive(ROUTES.USER_PROFILE) ? IMAGES.ACTIVE_USER : IMAGES.USER_PROFILE} className="img-fluid " alt="user" /></a>
                                    <ul className="dropdown-menu notify-dropdown notify-dropdown-custom" style={{ left: 'auto',top:'138%' }}>
                                        <li onClick={() => {
                                            if (navRef.current.classList.contains("show")) {
                                                navRef.current.classList.remove("show");
                                            }
                                            navigate(ROUTES.USER_PROFILE)
                                        }}><a className="dropdown-item dropdown-item-new" style={{ borderBottom: "1px solid #6ed7ef" }}>
                                                <div className="notify-profile-list d-flex align-items-center gap-3">
                                                    <Link to={ROUTES.USER_PROFILE} className="nav-link" >Profile</Link>
                                                </div>
                                            </a></li>
                                        <li onClick={handleLogout}><a className="dropdown-item dropdown-item-new">
                                            <div className="notify-profile-list d-flex align-items-center gap-3">
                                                <Link to={ROUTES.SIGN_IN} className="nav-link" onClick={handleLogout}>Logout</Link>
                                            </div>
                                        </a></li>
                                    </ul>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default HeaderUser