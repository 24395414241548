import ERR_MESSAGE from "./../Helper/error-helper";

/**
 * pass in field
 * @param {*} param0
 * @returns
 */
export const handleValidation = ({ fields, setErrors }) => {
  let errors = {};
  let formIsValid = true;
  let reg = /^[\w.+]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  const emojiRegex =
    /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
  const {
    email,
    userName,
    price,
    total_user_allow,
    start_date,
    end_date,
    location,
    longitude,
    latitude
  } = fields;
  if (!userName || userName.trim().length === 0) {
    formIsValid = false;
    errors["userName"] = ERR_MESSAGE.EMPTY_ORGNAME;
  } else if (userName.length < 3) {
    formIsValid = false;
    errors["userName"] = ERR_MESSAGE.INVALID_ORGNAME_MIN;
  } else if (userName.length > 30) {
    formIsValid = false;
    errors["userName"] = ERR_MESSAGE.INVALID_ORGNAME_MAX;
  } else if (emojiRegex.test(userName)) {
    formIsValid = false;
    errors["userName"] = ERR_MESSAGE.EMOJI;
  }
  if (!email || email.trim().length === 0) {
    formIsValid = false;
    errors["email"] = ERR_MESSAGE.EMAIL_EMPTY;
  } else if (reg.test(email.toLowerCase()) === false) {
    formIsValid = false;
    errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
  }

  if (!total_user_allow || total_user_allow.trim().length === 0) {
    formIsValid = false;
    errors["total_user_allow"] = ERR_MESSAGE.USERS_EMPTY;
  }

  if (!price || price.trim().length === 0) {
    formIsValid = false;
    errors["price"] = ERR_MESSAGE.PRICE_EMPTY;
  }
  if (!start_date || start_date === "") {
    formIsValid = false;
    errors["start_date"] = ERR_MESSAGE.START_DATE_EMPTY;
  } else {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const selectedDate = new Date(start_date);
    selectedDate.setHours(0, 0, 0, 0);
  }
  if (!end_date || end_date === "") {
    formIsValid = false;
    errors["end_date"] = ERR_MESSAGE.END_DATE_EMPTY;
  } else {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const selectedDate = new Date(end_date);
    selectedDate.setHours(0, 0, 0, 0);
    if (selectedDate < currentDate) {
      formIsValid = false;
      errors["end_date"] = ERR_MESSAGE.START_DATE_INVALID;
    }
  }

   if (!location || location.trim().length === 0) {
     formIsValid = false;
     errors["location"] = ERR_MESSAGE.LOCATION_EMPTY;
   } else if (
     !longitude ||
     longitude.trim().length === 0 ||
     !latitude ||
     latitude.trim().length === 0
   ) {
     formIsValid = false;
     errors["location"] = ERR_MESSAGE.INVALID_LOCATION;
   } else if (emojiRegex.test(location)) {
     formIsValid = false;
     errors["location"] = ERR_MESSAGE.EMOJI;
   }

  setErrors(errors);
  return formIsValid;
};
