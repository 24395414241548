const titles = {
    organization: 'Organizations | Haptapt',
    organizationDettail: 'Organization Detail | Haptapt',
    category: 'Categories | Haptapt',
    billing: 'Billing | Haptapt',
    profile: 'Profile | Haptapt',
    forgotPassword: 'Forgot Password | Haptapt',
    signIn: 'Sign In | Haptapt',
    signUp: 'Sign Up | Haptapt',
    searchEmployee: 'Search - members | Haptapt',
    searchResults: 'Search - Results | Haptapt',
    employees: 'Members | Haptapt',
    employeeProfile: 'Member - Profile | Haptapt',
    profileSetting: 'Profile - Settings | Haptapt',
    searchCoworkers: 'Search - Coworkers | Haptapt',
  };

  export default titles