
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
// import { Poppins, Roboto } from "next/font/google";

// export const HeadingsFontFamily = Poppins({
//   subsets: ["latin"],
//   variable: "--font-Poppins",
//   weight: ["300", "400", "500", "600", "700", "800"],
// });

// export const BodyFontFamily = Roboto({
//   subsets: ["latin"],
//   variable: "--font-Roboto",
//   weight: ["100", "300", "400", "500", "700", "900"],
// });

// TODO: work on color contrast
const theme = responsiveFontSizes(
  createTheme({
    // palette: {
    //   text: {
    //     primary: "#352f36",
    //   },
    //   primary: {
    //     main: "#008061",
    //     light: "#34cba6",
    //   },
    //   error: {
    //     main: "#cc3333",
    //   },
    //   warning: {
    //     main: "#ed6c02",
    //   },
    //   success: {
    //     main: "#339966",
    //   },
    //   secondary: {
    //     main: "#F5821f",
    //     light: "#f4f5f7",
    //   },
    //   // contrastThreshold: 4.5,
    // },
    typography: {
      button: {
        textTransform: "none",
      },

      // fontFamily: MontserratVariable.style.fontFamily,

      h1: {
        fontSize: "4rem",
        fontWeight: 700,
      },
      h2: {
        fontSize: "3rem",
        fontWeight: 600,
      },
      h3: {
        fontSize: "2.5rem",
        fontWeight: 600,
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: 600,
      },
      h5: {
        fontSize: "1.2rem",
        fontWeight: 600,
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: "0.75rem",
        fontWeight: 300,
      },

    },
    components: {
      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //       InputLabelProps: {
      //         shrink: true,
      //       },
      //     },
      //   },
      // },
      // MuiCard: {
      //   styleOverrides: {
      //     root: {
      //       boxShadow: "none",
      //       border: "1px solid #e0e0e0",
      //     },
      //   },
      // },
      // MuiLoadingButton: {
      //   styleOverrides: {
      //     root: {
      //       textTransform: "none",
      //       // fontSize: "1rem",
      //       // fontWeight: 700,
      //       // "&:hover": {
      //       //   color: "#fff",
      //       // },
      //       "& .MuiLoadingButton-loadingIndicator": {
      //         color: "#fff",
      //       },
      //       "&.MuiLoadingButton-loading": {
      //         background: "#008061",
      //         opacity: 0.8,
      //       },
      //     },
      //   },
      // },
      // MuiButton: {
      //   styleOverrides: {
      //     root: {
      //       fontWeight: 600,
      //       boxShadow: "none",
      //     },
      //   },
      // },
      // MuiDivider: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: "#008061",
      //     },
      //   },
      // },
      // MuiInputBase: {
      //   styleOverrides: {
      //     root: {
      //       shrink: true,
      //     },
      //   },
      // },

      // MuiInputLabel: {
      //   styleOverrides: {
      //     shrink: true,
      //   },
      // },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& $notchedOutline": {
              borderColor: "red",
            },
            "&:hover:not($disabled):not($focused):not($error) $notchedOutline":
              {
                borderColor: "your_custom_hover_color",
              },
          },
        },
      },

      MuiSelect: {
        styleOverrides: {},
      },
    },
  })
);

export default theme;
