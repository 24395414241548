import React, { useEffect, useRef, useState } from 'react'
import IMAGES from '../../Middleware/images'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ERR_MESSAGE from '../../Helper/error-helper';
import ReactLoader from '../../ControlComponents/react-loader';
import "../../Assets/Styles/category.css"
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import titles from '../../Helper/title';
import 'react-toastify/dist/ReactToastify.css';


const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 676,
    // bgcolor: 'background.paper',
    // borderRadius: "20px",
    // boxShadow: 24,
    // border: "1px solid #6ED7EF",
    // p: 0,
    // outline: 0,
    // textAlign: "center",
};


const Category = () => {
    const [search, setSearch] = useState('')
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [errors, setErrors] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [filterCategoryList, setFilterCategoryList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [fields, setFields] = useState({
        category: "",
        subCat: "",
            subCatArr: []
    });
    const [deltId, setDeltId] = useState(null);
    const textRef = useRef();


    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name;
        const value = e.target.value;

        setFields({
            ...fields,
            [name]: value,
        });
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setErrors("")
        setFields(
            {
                category: "",
                subCat: "",
                subCatArr: []
            }
        )
        setOpen(false);
    }

    const handleEditOpen = (id, catName, subcatdata) => {
        setDeltId(id)
        setEditOpen(true);
        setFields({
            category: catName,
            subCat: "",
            subCatArr: subcatdata?.map((item) => item.name)
        })
    }

    const handleEditClose = () => {
        setErrors("")
        setFields(
            {
                category: "",
                subCat: "",
                subCatArr: []
            }
        )
        setEditOpen(false);
    }

    const handleDeleteOpen = (id) => {
        setDeltId(id)
        setOpenDelete(true);
    }

    const handleDeleteClose = () => {
        setOpenDelete(false);
        setDeltId(null)
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        const { category, subCatArr } = fields;
        if (!category || category.trim().length === 0) {
            formIsValid = false;
            errors["category"] = ERR_MESSAGE.CATEGORY_EMPTY
        }   
        if (!subCatArr || subCatArr.length === 0) {
            formIsValid = false;
            errors["subcatArr"] = ERR_MESSAGE.OPTIONS_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    };

    const success = (msg) => {
        toast.success(msg,
            {   

                autoClose: 5000,
            });
    }
    const error = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }

  async function postAPI(e) {
        e.preventDefault();
        if (handleValidation()) {
            let data = JSON.stringify({
                "name": fields.category,
                "subcatArr": fields.subCatArr,
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/create-category", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleClose();
                getAPI()
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }
    
    async function postAPIEditCat(e) {
        e.preventDefault();
        if (handleValidation()) {
            let data = JSON.stringify({
                "cat_id": deltId,
                "name": fields.category,
                "subcatArr": fields.subCatArr
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/edit-category", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                success(result.message);
                handleEditClose();
                getAPI()
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }

    async function getAPI() {
        let id = localStorage.getItem("id")
        setLoading(true)
        let result = await apiHelper.getRequest(`auth/admin-category-list?id=${id}&sort=alpha`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setCategoryList(result?.body)

            setLoading(false)   
        }
        else {
            setLoading(false)
        }
    }

    async function getDeleteAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest(`auth/delete-category?id=${deltId}`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false)
            success(result.message)
            handleDeleteClose()
            getAPI()
        }
        else {
            error(result.message)
            setLoading(false)
        }
    }
    const handleSearch = (e) => {
        let text = e.target.value;
        setSearch(text);
        const filteredProducts = categoryList.filter((product) =>
            product.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilterCategoryList(filteredProducts)
    }
    const handleClear = () => {
        setSearch("");
      };
    useEffect(() => {
        getAPI()
    }, [])

    return (
        <>
            <Helmet>
                <title>{titles.category}</title>
            </Helmet>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <section className="pt-5">
                <div className="container">
                    <div className="row align-items-center pb-4">
                        <div className="col-12 col-lg-5">
                            <div className="list-head">
                                <h2>Categories</h2>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">
                            <div className="category-head d-block d-sm-flex justify-content-start justify-content-lg-end align-items-center gap-2 gap-xl-5">
                                <div className="search-category position-relative mb-2 mb-sm-0">
                                    <input type="serach" placeholder="Search categories..." value={search}
                                        onChange={(e) => handleSearch(e)} />
                                    <img src={IMAGES.SEARCH_ICON} className="search-icon" alt="" />
                                    {search!=="" && <img src={IMAGES.CROSS_1} className ="searchCross" style={{cursor:"pointer"}} onClick={handleClear}/>}
                                </div>
                                <div className="add-category position-relative">
                                    <button type="button" className="yellow-btn add-category-btn d-flex align-items-center" onClick={handleOpen}>add new categories <img src={IMAGES.ARROW_GREY} alt="arrow" className="img-fluid ms-2" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        search.length <= 0 ?
                            <>
                                {
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="category-list">
                                                <ul>

                                                    {categoryList.map((item) => {
                                                        return (
                                                            <li key={item.id}>
                                                                <div className="list-category-main d-flex justify-content-between align-items-center">
                                                                    <div className="list-category-left">
                                                                        <div className="list-category-head">
                                                                            <p>{item.name}</p>
                                                                        </div>
                                                                        <div className="list-content mt-3">
                                                                            {
                                                                                item.subcatdata.map((subcat) => {
                                                                                    return (
                                                                                        <p key={subcat.id} className="list-content-item mb-2 mb-xxl-3">{subcat.name}</p>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="list-category-right">
                                                                        <img onClick={() => handleDeleteOpen(item.id)} src={IMAGES.TRASH_ICON} alt="delete-icon" style={{ cursor: "pointer" }} className="img-fluid" />
                                                                        <img style={{ cursor: "pointer" }} onClick={() => handleEditOpen(item.id, item.name, item?.subcatdata)} src={IMAGES.EDIT_ICON} alt="edit-icon" className="img-fluid" />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {
                                    filterCategoryList.length <= 0 ?
                                        <p className='notCategory'>No category found</p> :
                                        <>
                                            {
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="category-list">
                                                            <ul>

                                                                {filterCategoryList.map((item) => {
                                                                    return (
                                                                        <li key={item.id}>
                                                                            <div className="list-category-main d-flex justify-content-between align-items-center">
                                                                                <div className="list-category-left">
                                                                                    <div className="list-category-head">
                                                                                        <p>{item.name}</p>
                                                                                    </div>
                                                                                    <div className="list-content mt-3">
                                                                                        {
                                                                                            item.subcatdata.map((subcat) => {
                                                                                                return (
                                                                                                    <p key={subcat.id} className="list-content-item mb-2 mb-xxl-3">{subcat.name}</p>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                                <div className="list-category-right">
                                                                                    <img onClick={() => handleDeleteOpen(item.id)} src={IMAGES.TRASH_ICON} alt="delete-icon" style={{ cursor: "pointer" }} className="img-fluid" />
                                                                                    <img onClick={() => handleEditOpen(item.id, item.name, item.subcatdata)} src={IMAGES.EDIT_ICON} alt="edit-icon" className="img-fluid" />
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                }

                            </>
                    }

                </div>
            </section>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal modal-custom">
                <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                      <div className="modal-content modal-content-custom">
                    <div onClick={handleClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                        <img src={IMAGES.CROSS} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h1>add new category</h1>
                    </div>
                    <div className="mb-4  position-relative px-3">
                        <p>
                            Please provide the name of the category and its corresponding options for the platform's input.
                        </p>
                    </div>
                    <form onSubmit={postAPI} >
                        <div className="mb-3 mb-lg-5 position-relative mx-5">
            
                            <TextField fullWidth label="Enter category name" variant="outlined"
                        
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                    name="category" onChange={handleChange}  value={fields.category} />
                          { errors.category && <span className="err_msg">{errors.category}</span>}
                        </div>

                        <div className="mb-3 mb-lg-5 position-relative mx-5">

                            <Autocomplete
                                clearIcon={<ClearIcon 
                                    onClick={(e)=>{
                                        setFields({
                                            ...fields,
                                            subCatArr:[],
                                            subCat:""
                                        });
                                        setErrors("")
                                }} 
                                fontSize='small'/>}
                                multiple
                                options={[]}
                                name='subCatArr'
                                value={fields.subCatArr}
                                onChange={(event, newValue) => {
                                    setErrors("")
                                    setFields({
                                        ...fields,
                                        subCatArr: newValue,
                                    });
                                }}
                                inputValue={fields.subCat}
                                
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value?.map((option, index) => (
                                        <Chip label={option}
                                            sx={{
                                                backgroundColor: "#EBFBFF",
                                                color: "#231F20",
                                                "& .MuiChip-deleteIcon": {
                                                    color: ""   
                                                }
                                            }}
                                            {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField 
                                    ref={textRef}
                                        fullWidth 
                                        {...params}
                                        label="Enter options" variant="outlined"
                                        onBlur={(e)=>{  
                                            if(e.target.value.trim().length!=0 && fields.subCatArr.includes(e.target.value)=== false){
                                                setFields({
                                                    ...fields,
                                                    subCatArr: [...fields.subCatArr, e.target.value],
                                                    subCat:""
                                                })
                                            }
                                            return;
                                           
                                        }}
                                        onKeyDownCapture={(e)=>{
                                            
                                            if(e.code == "Enter" || e.code == "NumpadEnter"|| e.key == ","){
                                             
                                                if(e.target.value.trim().length!=0 && fields.subCatArr.includes(e.target.value)=== false)
                                                {
                                                    e.preventDefault();
                                                setFields({
                                                    ...fields,
                                                    subCatArr: [...fields.subCatArr, e.target.value],
                                                    subCat:""
                                                })
                                            }
                                            else if(e.target.value.trim().length == 0){
                                                setFields({
                                                    ...fields,
                                                    subCatArr: [...fields.subCatArr],
                                                    subCat:""
                                                })
                                            }
                                              
                                        }
                                         return;
                                           
                                            
                                        }}
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                    name="subCat" onChange={handleChange}  value={fields.subCat} />
                                )}
                            />
                    {     errors.subcatArr &&   <span className="err_msg">{errors.subcatArr}</span>}

                        </div>

                        <div className="text-center mb-5 position-relative submit-head">
                            <button type="submit" className="yellow-btn d-flex align-items-center">Create <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                        </div>
                    </form>
                    </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={editOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal modal-custom ">
                <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                      <div className="modal-content modal-content-custom">
                    <div onClick={handleEditClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                        <img src={IMAGES.CROSS} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h1>Edit Category</h1>
                    </div>
                    <div className="mb-4  position-relative px-3">
                        <p>
                            Please modify the category name and its associated options for input on the platform. </p>
                    </div>
                    <form onSubmit={postAPIEditCat} >
                        <div className="mb-3 mb-lg-5 position-relative mx-5">
                        <TextField fullWidth label="Enter category name" variant="outlined"
                       sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                    name="category" onChange={handleChange}  value={fields.category} />
                       { errors.category &&    <span className="err_msg">{errors.category}</span>}
                        </div>
                        <div className="mb-3 mb-lg-5 position-relative mx-5">
                            <Autocomplete
                             clearIcon={<ClearIcon 
                                onClick={(e)=>{
                                    setFields({
                                        ...fields,
                                        subCatArr:[],
                                        subCat:""
                                    });
                                    setErrors("")
                            }} 
                            fontSize='small'/>}
                                multiple
                                options={[]}
                                name='subCatArr'
                                defaultValue={fields.subCatArr}
                                value={fields.subCatArr}
                                onChange={(event, newValue) => {
                                    setErrors("")
                                    setFields({
                                        ...fields,
                                        subCatArr: newValue,
                                    });
                                }}
                                inputValue={fields.subCat}
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={option}
                                            sx={{
                                                backgroundColor: "#EBFBFF",
                                                color: "#231F20",
                                                "& .MuiChip-deleteIcon": {
                                                    color: ""
                                                }
                                            }}
                                            {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField 
                                    fullWidth 
                                    {...params}
                                    label="Enter options" variant="outlined"
                                    onBlur={(e)=>{  
                                        if(e.target.value.trim().length!=0){
                                            setFields({
                                                ...fields,
                                                subCatArr: [...fields.subCatArr, e.target.value],
                                                subCat:""
                                            })
                                        }
                                        return;
                                       
                                    }}
                                    onKeyDownCapture={(e)=>{
                                        if(e.code == "Enter" || e.code == "NumpadEnter" || e.key == ","){
                                            if(e.target.value.trim().length!=0){
                                                e.preventDefault();
                                                setFields({
                                                    ...fields,
                                                    subCatArr: [...fields.subCatArr, e.target.value],
                                                    subCat:""
                                                })
                                            }
                                            else if(e.target.value.trim().length == 0){
                                                setFields({
                                                    ...fields,
                                                    subCatArr: [...fields.subCatArr],
                                                    subCat:""
                                                })
                                            }
                                            
                                           
                                        }
                                        return;
                                       
                                        
                                    }}
                                    sx={{
                                        "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                        "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                    }}
                                name="subCat" onChange={handleChange}  value={fields.subCat} />
                                )}
                                                            />
                         { errors.subcatArr &&  <span className="err_msg">{errors.subcatArr}</span>}
                        </div>

                        <div className="text-center mb-5 position-relative submit-head">
                            <button type="submit" className="yellow-btn d-flex align-items-center">Update <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                        </div>
                    </form>
                    </div>
                    </div>
                </Box>
            </Modal>
       
            <Modal
                open={openDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal modal-custom">
                <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                      <div className="modal-content modal-content-custom">
                    <div onClick={handleDeleteClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                        <img src={IMAGES.CROSS} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>delete category</h2>
                    </div>
                    <div className="mb-4  position-relative">
                        <p>
                            Are you sure you want to delete the category name and its associated tags?
                        </p>
                    </div>
                    <div className="mb-5 text-center position-relative submit-head">
                        <button type="submit" className="yellow-btn d-flex align-items-center" onClick={getDeleteAPI}>Delete <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                    </div>
                    </div>
                    </div>
                </Box>
            </Modal>
            <ToastContainer rtl progressClassName="progress-bar-cus"/>
             </>
    )
}
export default Category
